import styled from 'styled-components/macro';

export interface TextColumnProps {
  flexBasis?: string;
}

export const TextRow = styled.div`
  display: flex;
`;

export const TextColumn = styled.div<TextColumnProps>`
  flex: 1;
  flex-basis: ${({ flexBasis }) => flexBasis || null};
`;

export const TextHeaderColumn = styled(TextColumn)`
  font-weight: 600;
`;
