import React from 'react';
import { IntlProvider } from 'react-intl';
import { useParams } from 'react-router-dom';

import messages from 'locales.json';

import { DocsPage } from './DocsPage';

export type DocsPageParams = {
  locale: keyof typeof messages;
  account: string;
  solution: string;
};

export const DocsContainer: React.FunctionComponent = () => {
  const { locale, account, solution } = useParams<DocsPageParams>();

  if (!locale || !account || !solution) {
    return null;
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <DocsPage accountId={account} solutionId={solution} />
    </IntlProvider>
  );
};
