import React from 'react';
import ReactSyntaxHighlighter, { SyntaxHighlighterProps } from 'react-syntax-highlighter';
// eslint-disable-next-line import/no-unresolved
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from 'styled-components/macro';

export const SyntaxHighlighter: React.FunctionComponent<SyntaxHighlighterProps> = (props) => (
  <ReactSyntaxHighlighter
    language="json"
    style={atomOneDark}
    customStyle={{
      overflow: 'auto',
      maxWidth: '100%',
      maxHeight: '80vh',
      padding: '16px 15px',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      backgroundColor: '#26262C',
      borderRadius: '5px',
      margin: '0 0 8px 0',
    }}
    codeTagProps={{
      style: {
        fontFamily: 'Consolas, Menlo, Courier, monospace',
        color: '#abb2bf',
        fontSize: '13px',
        lineHeight: '1.4',
      },
    }}
    {...props}
  />
);

/** Same styles as SyntaxHighlighter, but accepts formatted text. */
export const TextSyntaxHighlighter = styled.pre`
  background: transparent;
  color: #abb2bf;

  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 13px;
  line-height: 1.25;

  overflow: auto;
  max-width: 100%;
  max-height: 80vh;
  padding: 7px 0;
  white-space: pre-wrap;
  word-break: break-all;
`;
