import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { SyntaxHighlighter } from 'components/base/SyntaxHighlighter';
import { createCurlDataExample, createRecordExample } from 'lib/examples';
import { getApplicationRecordsUrl } from 'lib/urls';
import { appSection } from 'translations/appSection';

import {
  ApplicationNamePill,
  CodeSectionTitle,
  DocsSection,
  DocsSectionCode,
  DocsSectionText,
} from '../DocSection';
import { ApplicationContext } from './ApplicationContext';

export interface CreateRecordSectionProps {}

export const CreateRecordSection: React.FunctionComponent<CreateRecordSectionProps> = React.memo(
  () => {
    const { application, accountId, apiToken } = useContext(ApplicationContext);

    const curlExample = createCurlDataExample(
      'POST',
      getApplicationRecordsUrl(application.id),
      createRecordExample(application),
      accountId,
      apiToken
    );

    return (
      <DocsSection>
        <DocsSectionText>
          <h3 id={`${application.slug}-create`}>
            <FormattedMessage {...appSection.createRecordsTitle} />
          </h3>

          <p>
            <FormattedMessage
              {...appSection.createRecordText1}
              values={{
                strong: (text: string) => <strong>{text}</strong>,
                b: (children: string) => <ApplicationNamePill>{children}</ApplicationNamePill>,
                applicationName: application.name,
              }}
            />
          </p>

          <p>
            <code>
              {process.env.REACT_APP_API_DOMAIN}/api/v1/applications/<strong>[Table Id]</strong>
              /records/
            </code>

            <ul>
              <li>
                <FormattedMessage {...appSection.createRecordNote1} />
              </li>
            </ul>
          </p>

          <p>
            <FormattedMessage {...appSection.createRecordText2} />
            <FormattedMessage
              {...appSection.createRecordNotes}
              values={{
                ol: (children: string) => <ol>{children}</ol>,
                li: (children: string) => <li>{children}</li>,
              }}
            />
          </p>
          <p>
            <FormattedMessage
              {...appSection.bulkCreateRecordText}
              values={{
                link: (children: string) => (
                  <a
                    href="https://developers.smartsuite.com/docs/solution-data/records/bulk-add-records"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {children}
                  </a>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage {...appSection.createRecordText3} />
          </p>
          <p>
            <FormattedMessage {...appSection.createRecordText4} />

            <ol>
              <li>
                <FormattedMessage id="create-record-not-allowed-1" defaultMessage="Auto Number" />
              </li>
              <li>
                <FormattedMessage id="create-record-not-allowed-2" defaultMessage="Count" />
              </li>
              <li>
                <FormattedMessage id="create-record-not-allowed-3" defaultMessage="First Created" />
              </li>
              <li>
                <FormattedMessage id="create-record-not-allowed-4" defaultMessage="Formula" />
              </li>
              <li>
                <FormattedMessage id="create-record-not-allowed-5" defaultMessage="Last Updated" />
              </li>
              <li>
                <FormattedMessage id="create-record-not-allowed-6" defaultMessage="Record ID" />
              </li>
              <li>
                <FormattedMessage id="create-record-not-allowed-7" defaultMessage="Rollup" />
              </li>
              <li>
                <FormattedMessage id="create-record-not-allowed-8" defaultMessage="Vote" />
              </li>
            </ol>
          </p>
        </DocsSectionText>
        <DocsSectionCode>
          <CodeSectionTitle>
            <FormattedMessage {...appSection.exampleRequest} />
          </CodeSectionTitle>

          <SyntaxHighlighter language="bash">{curlExample}</SyntaxHighlighter>

          <CodeSectionTitle>
            <FormattedMessage {...appSection.exampleResponse} />
          </CodeSectionTitle>

          <SyntaxHighlighter language="json">
            {JSON.stringify(createRecordExample(application), null, 2)}
          </SyntaxHighlighter>
        </DocsSectionCode>
      </DocsSection>
    );
  }
);
