import React, { FunctionComponent, HTMLAttributes } from 'react';
import { TransformOptions } from 'filestack-js';

import { File } from 'lib/models/file.model';

import { useFilestack } from './useFilestack';

export interface FileStackImageProps extends HTMLAttributes<HTMLImageElement> {
  file: File;
  transformOptions?: TransformOptions;
}

export const FileStackImage: FunctionComponent<FileStackImageProps> = ({
  file,
  transformOptions,
  ...props
}) => {
  const client = useFilestack();

  const image = client.transform(file.handle, transformOptions || file.transform_options);

  const imageStyle = {
    backgroundImage: `url('${image}')`,
  };

  return <div {...props} style={imageStyle} />;
};
