import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';

import { UserProfilePanel } from 'components/user/UserProfilePanel';
import { useOnClickOutside } from 'lib/hooks/useOnClickOutside';
import { AccountModel } from 'lib/models/account.model';
import { SolutionModel } from 'lib/models/solution.model';
import { main } from 'translations/main';

import { DocsPageParams } from '../docs/DocsContainer';

export interface NavBarProps {
  solutions?: SolutionModel[] | null;
  account?: AccountModel;
  selectedSolution?: SolutionModel;
}

export const NavBar: React.FunctionComponent<NavBarProps> = ({
  selectedSolution,
  solutions,
  account,
}) => {
  const intl = useIntl();
  const { locale, account: accountId } = useParams<DocsPageParams>();

  const [enableDropdown, setEnableDropdown] = useState(false);
  const [searchText, setSearchText] = useState('');

  const toggleDropdown = useCallback(() => {
    setEnableDropdown((enabled) => !enabled);
    setSearchText('');
  }, []);

  const iconName = selectedSolution?.logo_icon ?? 'square';

  const excludeElement = useRef<HTMLDivElement>(null);
  const targetRefElement = useRef<HTMLDivElement>(null);

  const filteredSolutions = matchSorter(solutions ?? [], searchText, { keys: ['name'] });

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && enableDropdown) {
        setEnableDropdown(false);
      }
    };
    document.body.addEventListener('keydown', handleKeyPress);
    return () => document.body.removeEventListener('keydown', handleKeyPress);
  }, [enableDropdown]);

  useOnClickOutside(targetRefElement, () => setEnableDropdown(false), excludeElement);

  return (
    <NavWrapper>
      <AlignedFlexDiv>
        <HeaderName data-testid="SolutionName" onClick={toggleDropdown} ref={excludeElement}>
          <AwesomeIconFrame logoColor={selectedSolution?.logo_color ?? '#889'}>
            <i className={`fad fa-${iconName} fa-2x`} />
          </AwesomeIconFrame>
          <div>
            <h6>Smartsuite API </h6>
            <h4>
              {selectedSolution?.name}
              <AwesomeArrowContainer isSolutionSelectorOpen={enableDropdown}>
                <i className="fas fa-caret-down" />
              </AwesomeArrowContainer>
            </h4>
          </div>
        </HeaderName>
        <Menu isSolutionSelectorOpen={enableDropdown} ref={targetRefElement}>
          <nav>
            {solutions && solutions?.length > 5 && (
              <SearchBar>
                <span style={{ color: '#C7CDCF', marginRight: '10px' }}>
                  <i className="fas fa-search" />
                </span>
                <input
                  type="text"
                  placeholder={intl.formatMessage(main.search)}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </SearchBar>
            )}
            <SolutionList>
              {filteredSolutions?.map((solutionItem) => (
                <SolutionListItem
                  key={solutionItem.id}
                  selected={solutionItem.id === selectedSolution?.id}
                  onClick={toggleDropdown}
                >
                  <SolutionLink to={`/docs/${locale}/${accountId}/solution/${solutionItem.id}`}>
                    <AwesomeIconFrame logoColor={solutionItem.logo_color}>
                      <i className={`fad fa-${solutionItem.logo_icon} fa-2x`} />
                    </AwesomeIconFrame>

                    <SolutionListItemText>{solutionItem.name}</SolutionListItemText>
                  </SolutionLink>
                </SolutionListItem>
              ))}

              {!filteredSolutions?.length && (
                <NoResults>
                  <FormattedMessage {...main.noResults} />
                </NoResults>
              )}
            </SolutionList>
          </nav>
        </Menu>
      </AlignedFlexDiv>
      <UserProfilePanel solution={selectedSolution} account={account} />
    </NavWrapper>
  );
};

interface MenuSolutionLinkProps {
  logoColor: string;
}

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background-color: #f3f3f4;
  border-radius: 5px;
  height: 32px;
  margin: 10px 10px 5px;
  padding: 0 5px 0 10px;

  input {
    border: none;
    box-shadow: none;
    outline: none;
    color: #2e3538;
    background-color: transparent;
    line-height: 32px;
  }
`;

const AlignedFlexDiv = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  height: 48px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const SolutionList = styled.ul`
  list-style: none;
  margin: 0 2px 0 0;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
`;

interface SolutionLinkProps {
  selected?: boolean;
}
const SolutionListItem = styled.li<SolutionLinkProps>`
  font-size: 24px;
  cursor: pointer;

  a {
    color: ${({ selected }) => (selected ? '#2E3538' : '#6B6B6B')};
  }
`;

const SolutionListItemText = styled.span`
  margin-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  max-width: 100%;
  white-space: nowrap;
`;

const AwesomeIconFrame = styled.button<MenuSolutionLinkProps>`
  background-color: ${({ logoColor }) => logoColor};
  border-radius: 5px;
  color: white;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  position: relative;
  z-index: 2;
  flex: 0 0 auto;

  font-size: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const SolutionLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;

  &:hover {
    background-color: #f3f3f4;
    color: #2e3538;
  }
`;

const HeaderName = styled.div`
  margin: auto 0px;
  font-weight: 600;
  color: #2e3538;
  padding-right: 10px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  button {
    margin-right: 20px;
  }

  h6 {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    color: ${(props) => props.theme.solutionColor};
  }

  h4 {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 22px;
  }
`;

interface MenuSolutionProps {
  isSolutionSelectorOpen?: boolean;
}

const AwesomeArrowContainer = styled.span<MenuSolutionProps>`
  position: relative;
  top: 2px;
  margin-left: 10px;
  font-size: 14px;
  transition: transform 0.2s ease-out;
  transform: ${({ isSolutionSelectorOpen }) =>
    isSolutionSelectorOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  z-index: 2;
`;

const Menu = styled.div<MenuSolutionProps>`
  width: calc(100% + 50px);
  position: absolute;
  top: -10px;
  left: -10px;
  background: white;
  color: #000;
  opacity: ${({ isSolutionSelectorOpen }) => (isSolutionSelectorOpen ? '1' : '0')};
  visibility: ${({ isSolutionSelectorOpen }) => (isSolutionSelectorOpen ? 'visible' : 'hidden')};
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  box-shadow: 0 4px 10px 0px rgba(128, 128, 128, 0.5);
  outline: none;
  border: none;
  border-radius: 5px;
  max-height: calc(100vh - 15px);
  cursor: default;
  display: flex;

  nav {
    border-top: 1px solid rgba(222, 222, 222, 0.7);
    width: 100%;
    margin-top: 66px;
    display: flex;
    flex-direction: column;
  }
`;

const NavWrapper = styled.header`
  position: sticky;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem;
  padding-left: 25px;
  font-weight: 600;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);

  background: white;
  color: #000;
  font-size: 12px;
  vertical-align: middle;
  box-shadow: 0 0 1px;
  z-index: 100;
`;

const NoResults = styled.p`
  text-align: center;
  color: #999;
  font-size: 13px;
  margin: 3px 0 8px 0;
`;
