import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { AppStructureField } from 'lib/models/application.model';
import { appSection } from 'translations/appSection';

import { TextColumn, TextRow } from './ApplicationSharedStyles';

interface FieldParamsProps {
  field: AppStructureField;
}

export const FieldParams: FunctionComponent<FieldParamsProps> = ({ field }) => {
  if (!field.comparisons?.length) return null;

  return (
    <div>
      <FieldParmsSectionHeader>
        <FormattedMessage {...appSection.availableFilters} />
      </FieldParmsSectionHeader>

      <TextRow>
        <TextColumn flexBasis="50%">
          <ColumnTitle>
            <FormattedMessage {...appSection.comparisonLabelTitle} />
          </ColumnTitle>
        </TextColumn>
        <TextColumn flexBasis="50%">
          <ColumnTitle>
            <FormattedMessage {...appSection.comparisonValueTitle} />
          </ColumnTitle>
        </TextColumn>
      </TextRow>

      {field.comparisons.map(({ label, value }) => (
        <TextRow key={value}>
          <TextColumn flexBasis="50%">{label}</TextColumn>
          <MonospaceColumn flexBasis="50%">{value}</MonospaceColumn>
        </TextRow>
      ))}
    </div>
  );
};

const ColumnTitle = styled.div`
  font-weight: 400;
`;

const FieldParmsSectionHeader = styled.h4`
  font-weight: 400;
  margin-bottom: 8px;
`;

const MonospaceColumn = styled(TextColumn)`
  font-family: ${({ theme }) => theme.fonts.monospace};
`;
