import React, { useState, useMemo } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import orderBy from 'lodash/orderBy';

import {
  useApplicationsSWR,
  useSolutionsSWR,
  useApiToken,
  useUserSWR,
  useAccountSWR,
} from 'lib/api';
import { ApplicationModel } from 'lib/models/application.model';
import { makeTheme } from 'lib/theme';
import { Spinner } from 'components/base/Spinner';
import { CurrentUserContext } from 'components/user/CurrentUserContext';
import { GlobalStyle } from 'components/base/GlobalStyle';

import { NavBar } from '../nav/NavBar';
import { SideBar } from '../nav/SideBar';
import { DocsBody } from './DocsBody';

export interface DocsPageProps {
  accountId: string;
  solutionId: string;
}

export const DocsPage: React.FunctionComponent<DocsPageProps> = ({ accountId, solutionId }) => {
  const [activeApplication, setActiveApplication] = useState<ApplicationModel | null>(null);

  const { data: allSolutions } = useSolutionsSWR(accountId);
  const { data: allApplications } = useApplicationsSWR(accountId, solutionId);
  const apiToken = useApiToken();
  const { data: user } = useUserSWR(accountId);
  const { data: account } = useAccountSWR(accountId);

  const solutions = useMemo(
    () =>
      orderBy(
        allSolutions?.filter((solution) => !solution.hidden && !solution.delete_date),
        ({ name }) => name.toLowerCase()
      ),
    [allSolutions]
  );

  const solution = useMemo(
    () => (solutions ?? []).find((item) => item.id === solutionId),
    [solutionId, solutions]
  );

  // Need to filter just applications with current solutionId while endpoint is not doing that
  // Can be removed after 0.53 is released
  const applications = useMemo(
    () =>
      orderBy(
        allApplications?.filter((app) => app.solution === solutionId),
        ({ name }) => name.toLowerCase()
      ) ?? undefined,
    [allApplications, solutionId]
  );

  const theme = useMemo(() => makeTheme(solution), [solution]);

  if (!solution) {
    return <Spinner fullPage />;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <CurrentUserContext.Provider value={user || null}>
        <Helmet>
          <title>SmartSuite API - {solution.name}</title>
        </Helmet>

        <NavBar solutions={solutions} selectedSolution={solution} account={account} />

        <ContentWrapper>
          <SideBar applications={applications} activeApplication={activeApplication} />

          <DocsBody
            accountId={accountId}
            solution={solution}
            applications={applications}
            apiToken={apiToken}
            onActiveApplication={setActiveApplication}
          />
        </ContentWrapper>
      </CurrentUserContext.Provider>
    </ThemeProvider>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  background-color: #fafafa;
`;
