import { mutate } from 'swr';

export interface LocalStorageItem {
  version: number;
  data: any;
}

/** Increment this to invalidate the chache. */
export const LOCAL_STORAGE_VERSION = 1;

/**
 * Hydrates the SWR cache with values saved in local storage.
 */
export const hydrateSWRFromLocalStorage = () => {
  if (window) {
    Object.keys(window.localStorage).forEach((key) => {
      const data = window.localStorage.getItem(key);
      if (!data) return;

      let item: LocalStorageItem;

      try {
        item = JSON.parse(data);
      } catch (error) {
        return;
      }

      if (item.version !== LOCAL_STORAGE_VERSION) {
        removeFromLocalStorage(key);
        return;
      }

      mutate(key, item.data, false);
    });
  }
};

export const addToLocalStorage = <T>(data: T, key: string) => {
  if (window) {
    const item: LocalStorageItem = {
      version: LOCAL_STORAGE_VERSION,
      data,
    };

    try {
      window.localStorage.setItem(key, JSON.stringify(item));
    } catch (err) {
      // Ignore error if for some reason saving the cached value fails
      // This could happen if Storage quota is exceeded, for example

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(err, 'Local storage will be cleared');
      }

      // Clear local storage to make space for new data
      clearLocalCache();
    }
  }
};

export const removeFromLocalStorage = (key: string) => {
  if (window) {
    window.localStorage.removeItem(key);
  }
};

const clearLocalCache = () => {
  if (window) {
    window.localStorage.clear();
  }
};
