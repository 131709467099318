import debounce from 'lodash/debounce';
import React, { useRef } from 'react';

import { ApplicationModel } from 'lib/models/application.model';
import { useApplicationRecordsSWR } from 'lib/api';

import { ApplicationFieldsSection } from './ApplicationFieldsSection';
import { CreateRecordSection } from './CreateRecordSection';
import { DeleteRecordSection } from './DeleteRecordSection';
import { ListRecordsSection } from './ListRecordsSection';
import { RetrieveRecordSection } from './RetrieveRecordSection';
import { UpdateRecordSection } from './UpdateRecordSection';
import { ApplicationContext } from './ApplicationContext';
import { ListFieldsSection } from './ListFieldsSection';

const appDocsSectionBounding = 80;

export interface ApplicationDocsProps {
  accountId: string;
  apiToken: string;
  application: ApplicationModel;
  onActiveApplication(application: ApplicationModel): void;
}

export const ApplicationDocs: React.FunctionComponent<ApplicationDocsProps> = ({
  accountId,
  apiToken,
  application,
  onActiveApplication,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const onScroll = debounce(() => {
      const bounding = ref.current?.getBoundingClientRect();
      if (!bounding) return;

      const { top, bottom } = bounding;
      if (top <= appDocsSectionBounding && bottom >= appDocsSectionBounding) {
        onActiveApplication(application);
      }
    }, 100);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [application, onActiveApplication]);

  const { data: appRecords } = useApplicationRecordsSWR(accountId, application.id ?? null);
  const records = appRecords?.items;

  const applicationContext = React.useMemo(
    () => ({
      application,
      records: records || [],
      accountId,
      apiToken,
    }),
    [application, records, accountId, apiToken]
  );

  return (
    <div data-testid="WholeApplicationBlock" ref={ref}>
      <ApplicationContext.Provider value={applicationContext}>
        <ApplicationFieldsSection />
        <ListFieldsSection />
        <ListRecordsSection />
        <RetrieveRecordSection />
        <CreateRecordSection />
        <UpdateRecordSection />
        <DeleteRecordSection />
      </ApplicationContext.Provider>
    </div>
  );
};
