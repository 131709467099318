import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  createCurlRequestExample,
  deleteMultipleRecordsExample,
  exampleRecordId,
} from 'lib/examples';
import { getRecordUrl, getDeleteRecordsUrl } from 'lib/urls';
import { SyntaxHighlighter, TextSyntaxHighlighter } from 'components/base/SyntaxHighlighter';
import { appSection } from 'translations/appSection';

import {
  DocsSection,
  DocsSectionText,
  DocsSectionCode,
  CodeSectionTitle,
  ApplicationNamePill,
} from '../DocSection';
import { ApplicationContext } from './ApplicationContext';

export interface DeleteRecordSectionProps {}

export const DeleteRecordSection: React.FunctionComponent<DeleteRecordSectionProps> = React.memo(
  () => {
    const { application, records, accountId, apiToken } = useContext(ApplicationContext);

    const curlExample = createCurlRequestExample(
      'DELETE',
      getRecordUrl(application.id, exampleRecordId(records)),

      accountId,
      apiToken
    );

    const curlExampleMultiple = createCurlRequestExample(
      'DELETE',
      getDeleteRecordsUrl(application.id, records),
      accountId,
      apiToken
    );

    return (
      <DocsSection>
        <DocsSectionText>
          <h3 id={`${application.slug}-delete`}>
            <FormattedMessage {...appSection.deleteRecordTitle} />
          </h3>
          <p>
            <FormattedMessage
              {...appSection.deleteRecordText1}
              values={{
                b: (children: string) => <ApplicationNamePill>{children}</ApplicationNamePill>,
                applicationName: application.name,
              }}
            />
          </p>
          <p>
            <code>
              {process.env.REACT_APP_API_DOMAIN}/api/v1/applications/<strong>[Table Id]</strong>
              /records/
              <strong>[Record Id]</strong>/
            </code>

            <FormattedMessage
              {...appSection.deleteRecordTextNotes}
              values={{
                ul: (children: string) => <ul>{children}</ul>,
                li: (children: string) => <li>{children}</li>,
              }}
            />
          </p>

          <p>
            <FormattedMessage {...appSection.deleteRecordText2} />
          </p>
          <p>
            <FormattedMessage {...appSection.deleteRecordText3} />
          </p>

          <p>
            <FormattedMessage
              {...appSection.bulkDeleteRecordText}
              values={{
                link: (children: string) => (
                  <a
                    href="https://developers.smartsuite.com/docs/solution-data/records/bulk-delete-records"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {children}
                  </a>
                ),
              }}
            />
          </p>
        </DocsSectionText>
        <DocsSectionCode>
          <CodeSectionTitle>
            <FormattedMessage {...appSection.exampleRequestSingle} />
          </CodeSectionTitle>
          <SyntaxHighlighter language="bash">{curlExample}</SyntaxHighlighter>
          <CodeSectionTitle>
            <FormattedMessage {...appSection.exampleResponseSingle} />
          </CodeSectionTitle>
          <TextSyntaxHighlighter>
            <FormattedMessage {...appSection.noContent} />
          </TextSyntaxHighlighter>

          <CodeSectionTitle>
            <FormattedMessage {...appSection.exampleRequestMultiple} />
          </CodeSectionTitle>
          <SyntaxHighlighter language="bash">{curlExampleMultiple}</SyntaxHighlighter>
          <CodeSectionTitle>
            <FormattedMessage {...appSection.exampleResponseMultiple} />
          </CodeSectionTitle>
          <SyntaxHighlighter language="json">
            {JSON.stringify(deleteMultipleRecordsExample(records), null, 2)}
          </SyntaxHighlighter>
        </DocsSectionCode>
      </DocsSection>
    );
  }
);
