import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { AppStructureField } from 'lib/models/application.model';
import { appSection } from 'translations/appSection';

import {
  ApplicationIdPill,
  ApplicationNamePill,
  DocsSection,
  DocsSectionCode,
  DocsSectionText,
} from '../DocSection';
import { ApplicationContext } from './ApplicationContext';
import { ApplicationFieldRow } from './ApplicationFieldRow';
import { TextHeaderColumn, TextRow } from './ApplicationSharedStyles';

export interface ApplicationDocsProps {}

export const ApplicationFieldsSection: React.FunctionComponent<ApplicationDocsProps> = () => {
  const { application, records } = useContext(ApplicationContext);

  return (
    <>
      <DocsSection>
        <DocsSectionText data-testid="ApplicationName">
          <h2 id={application.slug}>
            <FormattedMessage
              {...appSection.applicationName}
              values={{ applicationName: application.name }}
            />
          </h2>

          <p>
            <FormattedMessage
              {...appSection.applicationID}
              values={{
                applicationId: application.id,
                b: (children: string) => (
                  <ApplicationIdPill data-testid="ApplicationID">{children}</ApplicationIdPill>
                ),
              }}
            />
          </p>

          <p>
            <FormattedMessage {...appSection.applicationBody1} />
          </p>

          <h3 id={`${application.slug}-fields`}>
            <FormattedMessage {...appSection.fieldListingTitle} />
          </h3>

          <p>
            <FormattedMessage {...appSection.fieldListingBody1} />
          </p>

          <p>
            <FormattedMessage
              {...appSection.applicationRecords}
              values={{
                applicationName: application.name,
                b: (children: string) => <ApplicationNamePill>{children}</ApplicationNamePill>,
              }}
            />
          </p>

          <br />
          <br />

          <TextRow>
            <TextHeaderColumn flexBasis="25%">
              <FormattedMessage id="FieldNameColumn" defaultMessage="Field Name" />
            </TextHeaderColumn>
            <TextHeaderColumn flexBasis="25%">
              <FormattedMessage id="FieldId" defaultMessage="Field Id" />
            </TextHeaderColumn>
            <TextHeaderColumn flexBasis="50%">
              <FormattedMessage id="FieldDescriptionColumn" defaultMessage="Description" />
            </TextHeaderColumn>
          </TextRow>
        </DocsSectionText>
        <DocsSectionCode />
      </DocsSection>

      {application.structure.map((field: AppStructureField) => (
        <ApplicationFieldRow key={field.slug} field={field} records={records} />
      ))}
    </>
  );
};
