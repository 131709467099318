import { useState, useEffect } from 'react';
import useSWR from 'swr';

import { fetchGetWithAccountId, fetchPostWithAccountId, fetchGet } from './fetcher';
import { ApplicationModel } from './models/application.model';
import { RecordsData } from './models/record.model';
import { SolutionModel } from './models/solution.model';
import { UserModel } from './models/user.model';
import { AccountModel } from './models/account.model';
import {
  hydrateSWRFromLocalStorage,
  addToLocalStorage,
  removeFromLocalStorage,
} from './localStorageCache';

hydrateSWRFromLocalStorage();

export const useSolutionsSWR = (accountId: string) => {
  return useSWR<SolutionModel[]>(
    [`${process.env.REACT_APP_API_DOMAIN}/api/v1/solutions`, accountId],
    fetchGetWithAccountId,
    {
      revalidateOnFocus: false,
      onSuccess: addToLocalStorage,
      onError: removeFromLocalStorage,
    }
  );
};

export const useApplicationsSWR = (accountId: string, solutionId: string) => {
  return useSWR<ApplicationModel[]>(
    [`${process.env.REACT_APP_API_DOMAIN}/api/v1/applications/?solution=${solutionId}`, accountId],
    fetchGetWithAccountId,
    {
      revalidateOnFocus: false,
      onSuccess: addToLocalStorage,
      onError: removeFromLocalStorage,
    }
  );
};

export const useApplicationRecordsSWR = (accountId: string, applicationId: string) => {
  return useSWR<RecordsData>(
    applicationId && [
      `${process.env.REACT_APP_API_DOMAIN}/api/v1/applications/${applicationId}/records/list/?offset=0&limit=3`,
      accountId,
    ],
    fetchPostWithAccountId,
    {
      revalidateOnFocus: false,
      onSuccess: addToLocalStorage,
      onError: removeFromLocalStorage,
    }
  );
};

export const useUserSWR = (accountId: string) => {
  return useSWR<UserModel>(
    [`${process.env.REACT_APP_API_DOMAIN}/api/v1/users/me`, accountId],
    fetchGetWithAccountId,
    {
      revalidateOnFocus: false,
      onSuccess: addToLocalStorage,
      onError: removeFromLocalStorage,
    }
  );
};

export const useAccountSWR = (accountId: string) => {
  return useSWR<AccountModel>(
    [`${process.env.REACT_APP_API_DOMAIN}/api/v1/accounts/${accountId}`, accountId],
    fetchGetWithAccountId,
    {
      revalidateOnFocus: false,
      onSuccess: addToLocalStorage,
      onError: removeFromLocalStorage,
    }
  );
};

/** Get the user's API token. */
export const useApiToken = () => {
  const [apiToken, setApiToken] = useState('YOUR_API_KEY');

  useEffect(() => {
    fetchApiToken().then((token) => {
      if (token) {
        setApiToken(token);
      }
    });
  }, []);

  return apiToken;
};

export const fetchApiToken = () =>
  // Don't cache this, since it could change at any time.
  fetchGet<string>(`${process.env.REACT_APP_API_DOMAIN}/api/v1/token/`);
