import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { colors } from 'lib/colors';

export interface SpinnerProps {
  size?: number;
  fullPage?: boolean;
  margin?: string;
}

export const Spinner: React.FunctionComponent<SpinnerProps> = ({
  size = 20,
  fullPage = false,
  margin,
}) => {
  return (
    <SpinnerRoot size={size} fullPage={fullPage} margin={margin || '0'}>
      <Bounce index={0} size={size} />
      <Bounce index={1} size={size} />
      <Bounce index={2} size={size} />
    </SpinnerRoot>
  );
};

const animate = keyframes`
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 
    40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
`;

const SpinnerRoot = styled.div<{ size: number; fullPage: boolean; margin: string }>`
  width: 100%;
  height: ${({ fullPage }) => fullPage && '100vh'};
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => margin};
`;

const Bounce = styled.div<{ index: number; size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin: 0 ${({ size }) => `${size / 4}px`};
  background-color: ${({ theme }) => theme?.solutionColor || colors.blue.base};
  border-radius: 100%;
  display: inline-block;
  animation: ${animate} 1.4s infinite ease-in-out both;
  animation-delay: ${({ index }) => 0.16 * index}s;
`;
