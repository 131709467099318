import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { ApplicationModel } from 'lib/models/application.model';
import { Spinner } from 'components/base/Spinner';
import { getDeepColor } from 'lib/colors';
import { appSection } from 'translations/appSection';

export interface SideBarProps {
  applications: ApplicationModel[] | undefined;
  activeApplication: ApplicationModel | null;
}

export const SideBar: React.FunctionComponent<SideBarProps> = ({
  applications,
  activeApplication,
}) => {
  return (
    <ParentStickySidebar>
      <StickySidebar>
        <IntroductionLink
          href="#introduction"
          selected={activeApplication === null || activeApplication?.slug === 'introduction'}
        >
          <FormattedMessage id="introduction" defaultMessage="Introduction" />
        </IntroductionLink>

        <SidebarLink href="#metaData" selected={activeApplication?.slug === 'metaData'}>
          <FormattedMessage id="metaData" defaultMessage="Metadata" />
        </SidebarLink>

        <SidebarLink href="#rateLimits" selected={activeApplication?.slug === 'rateLimits'}>
          <FormattedMessage id="rateLimits" defaultMessage="Rate Limits" />
        </SidebarLink>

        <SidebarLink href="#commonErrors" selected={activeApplication?.slug === 'commonErrors'}>
          <FormattedMessage id="commonErrors" defaultMessage="Common Errors" />
        </SidebarLink>

        <SidebarLink href="#faq" selected={activeApplication?.slug === 'commonErrors'}>
          <FormattedMessage id="faq" defaultMessage="FAQ" />
        </SidebarLink>

        <SidebarLink href="#authentication" selected={activeApplication?.slug === 'authentication'}>
          <FormattedMessage id="authentication" defaultMessage="Authentication" />
        </SidebarLink>

        {!applications && <Spinner size={8} margin="15px 0" />}

        {applications?.map((application) => (
          <React.Fragment key={application.id}>
            <SidebarLinkWrapper
              data-testid="ApplicationTab"
              openedSubmenu={application.slug === activeApplication?.slug}
            >
              <SidebarLink
                href={`#${application.slug}`}
                selected={application.slug === activeApplication?.slug}
              >
                {application.name}
                <span>
                  <i className="fas fa-chevron-right fa-xs" />
                </span>
              </SidebarLink>
            </SidebarLinkWrapper>
            <SidebarSubLinkWrapper openedSubmenu={application.slug === activeApplication?.slug}>
              <SidebarSubLink href={`#${application.slug}-fields`}>
                <FormattedMessage {...appSection.fieldListingTitle} />
              </SidebarSubLink>
              <SidebarSubLink href={`#${application.slug}-fields-list`}>
                <FormattedMessage {...appSection.listFieldsTitle} />
              </SidebarSubLink>
              <SidebarSubLink href={`#${application.slug}-list`}>
                <FormattedMessage {...appSection.listRecordsTitle} />
              </SidebarSubLink>
              <SidebarSubLink href={`#${application.slug}-retrieve`}>
                <FormattedMessage {...appSection.retrieveRecordTitle} />
              </SidebarSubLink>
              <SidebarSubLink href={`#${application.slug}-create`}>
                <FormattedMessage {...appSection.createRecordsTitle} />
              </SidebarSubLink>
              <SidebarSubLink href={`#${application.slug}-update`}>
                <FormattedMessage {...appSection.updateRecordTitle} />
              </SidebarSubLink>
              <SidebarSubLink href={`#${application.slug}-delete`}>
                <FormattedMessage {...appSection.deleteRecordTitle} />
              </SidebarSubLink>
            </SidebarSubLinkWrapper>
          </React.Fragment>
        ))}

        <SidebarLink href="#errorCodes" selected={activeApplication?.slug === 'errorCodes'}>
          <FormattedMessage id="errorCodes" defaultMessage="Error Codes" />
        </SidebarLink>
      </StickySidebar>
    </ParentStickySidebar>
  );
};

const SidebarLinkWrapper = styled.div<SidebarLinkProps>`
  position: relative;
  display: flex;
  align-items: center;

  span {
    position: absolute;
    right: 14px;
    top: 50%;
    height: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.15s ease-in-out 0s;
    transform: ${({ openedSubmenu }) =>
      openedSubmenu ? 'translateY(-50%) rotate(90deg)' : 'translateY(-50%) rotate(0)'};

    svg {
      height: 11px;
    }
  }
`;

const SidebarSubLinkWrapper = styled.div<SidebarLinkProps>`
  overflow: hidden;
  transition: height 500ms;
  height: ${({ openedSubmenu }) => (openedSubmenu ? 'auto' : '0')};
`;

const ParentStickySidebar = styled.div`
  border-color: hsla(0, 0%, 0%, 0.2);
  width: 264px;
  top: 0;
  background-color: ${(props) => getDeepColor(props.theme.solutionColor)};
`;

interface SidebarLinkProps {
  selected?: boolean;
  openedSubmenu?: boolean;
}

const StickySidebar = styled.nav<SidebarLinkProps>`
  max-height: calc(100vh - 80px); /* discount the header size */
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  width: 264px;
  top: 80px;
  padding: 25px 30px;
`;

const SidebarLink = styled.a<SidebarLinkProps>`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  font-weight: 600;
  text-decoration: none;
  color: ${({ selected }) => (selected ? 'white' : '#575757')};
  background-color: ${({ selected, theme }) => (selected ? theme.solutionColor : 'transparent')};
  padding: 6px 15px;
  margin-bottom: 1px;

  &:hover {
    color: #ffffff;
    background-color: ${({ theme }) => theme.solutionColor};
    text-decoration: none;

    span {
      border-bottom-color: #ffffff;
    }
  }
`;

const IntroductionLink = styled(SidebarLink)``;

const SidebarSubLink = styled(SidebarLink)`
  padding-left: 25px;
  font-weight: 400;
`;
