import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SolutionModel } from 'lib/models/solution.model';
import { main } from 'translations/main';

import {
  DocsSection,
  DocsSectionText,
  SolutionNamePill,
  SolutionIdPill,
  DocsSectionCode,
} from './DocSection';

interface IntroductionSectionProps {
  solution: SolutionModel;
}

export const IntroductionSection: React.FunctionComponent<IntroductionSectionProps> = ({
  solution,
}) => (
  <DocsSection data-testid="Introduction">
    <DocsSectionText>
      <h2 id="introduction">
        <FormattedMessage {...main.introduction} />
      </h2>

      <p>
        <FormattedMessage
          {...main.introductionBody1}
          values={{
            solutionName: solution.name,
            b: (children: string) => <SolutionNamePill>{children}</SolutionNamePill>,
          }}
        />
      </p>

      <p>
        <FormattedMessage
          {...main.introductionBody2}
          values={{
            solutionName: solution.name,
            b: (children: string) => <SolutionNamePill>{children}</SolutionNamePill>,
          }}
        />
      </p>

      <p>
        <FormattedMessage
          {...main.introductionBody3}
          values={{
            solutionId: solution.id,
            b: (children: string) => <SolutionIdPill>{children}</SolutionIdPill>,
          }}
        />
      </p>
      <p>
        <FormattedMessage {...main.introductionBody4} />
      </p>
      <p>
        <FormattedMessage
          {...main.introductionBody5}
          values={{
            ul: (children: string) => <ul>{children}</ul>,
            li: (children: string) => <li>{children}</li>,
            link1: (children: string) => (
              <a
                href="https://help.smartsuite.com/en/articles/4356333-smartsuite-api-overview"
                target="_blank"
                rel="noreferrer"
              >
                {children}
              </a>
            ),
            link2: (children: string) => (
              <a
                href="https://help.smartsuite.com/en/articles/4855681-generating-an-api-key"
                target="_blank"
                rel="noreferrer"
              >
                {children}
              </a>
            ),
          }}
        />
      </p>
    </DocsSectionText>
    <DocsSectionCode />
  </DocsSection>
);
