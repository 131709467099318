import { exampleRecordId } from './examples';
import { RecordModel } from './models/record.model';

export const API_BASE_URL = `${process.env.REACT_APP_API_DOMAIN}/api/v1`;

export const getApplicationUrl = (applicationId: string) =>
  `${API_BASE_URL}/applications/${applicationId}/`;

export const getApplicationRecordsUrl = (applicationId: string) =>
  `${API_BASE_URL}/applications/${applicationId}/records/`;

export const getApplicationListRecordsUrl = (applicationId: string) =>
  `${API_BASE_URL}/applications/${applicationId}/records/list/`;

export const getRecordUrl = (applicationId: string, recordId: string) =>
  `${API_BASE_URL}/applications/${applicationId}/records/${recordId}/`;

export const getApplicationFieldsUrl = (applicationId: string) =>
  `${API_BASE_URL}/applications/${applicationId}/fields`;

export const getDeleteRecordsUrl = (applicationId: string, records: RecordModel[] = []) => {
  const rec1 = exampleRecordId(records, 0);
  const rec2 = exampleRecordId(records, 1);
  return `${API_BASE_URL}/applications/${applicationId}?=records%20[%22${rec1}%22,%22${rec2}%22]/`;
};
