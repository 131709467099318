import { EmptyFieldsDefaultValue } from './emptyFieldsDefaultValue';
import { Fields } from './fields';
import { ApplicationModel } from './models/application.model';
import { RecordModel } from './models/record.model';

export type QueryParams = Record<string, string | number | boolean>;

export const getFieldValueExample = (fieldType: Fields) => {
  return EmptyFieldsDefaultValue[fieldType]?.[0];
};

export const createCurlRequestExample = (
  method: 'GET' | 'POST' | 'DELETE',
  url: string,
  accountId: string,
  apiToken: string,
  queryParams?: QueryParams
) => {
  const fullUrl = appendQueryParams(url, queryParams);

  return `\
curl -X ${method} ${fullUrl} \\
  -H "Authorization: Token ${apiToken}" \\
  -H "ACCOUNT-ID: ${accountId}"`;
};

export const createCurlDataExample = (
  method: 'POST' | 'PATCH' | 'PUT',
  url: string,
  data: Object,
  accountId: string,
  apiToken: string,
  queryParams?: QueryParams
) => {
  const fullUrl = appendQueryParams(url, queryParams);

  return `\
curl -X ${method} ${fullUrl} \\
  -H "Authorization: Token ${apiToken}" \\
  -H "ACCOUNT-ID: ${accountId}" \\
  -H "Content-Type: application/json" \\
  --data '${JSON.stringify(data, null, 2)}'
`;
};

export const createRecordExample = (application: ApplicationModel) => {
  return application.structure.reduce((record, field) => {
    // Can't set system fields directly.
    if (field.params.system) return record;

    return {
      ...record,
      [field.slug]: getFieldValueExample(field.field_type),
    };
  }, {});
};

/** And example of updating a field in a record. */
export const updateRecordExample = (application: ApplicationModel) => {
  // Find the first updatable (non-system) field.
  const fieldToUpdate = application.structure.find((field) => !field.params.system);

  if (!fieldToUpdate) return {};

  return {
    [fieldToUpdate.slug]: getFieldValueExample(fieldToUpdate.field_type),
  };
};

export const listRecordsExample = (application: ApplicationModel) => {
  return {
    total: 1,
    offset: 0,
    limit: 0,
    items: [
      application.structure.reduce(
        (curr, field) => ({
          ...curr,
          [field.slug]: getFieldValueExample(field.field_type),
        }),
        {}
      ),
    ],
  };
};

export const listFieldsExample = (application: ApplicationModel) => {
  return application;
};

export const deleteMultipleRecordsExample = (records: RecordModel[]) => {
  const rec1 = exampleRecordId(records, 0);
  const rec2 = exampleRecordId(records, 1);
  return [
    {
      id: rec1,
      deleted: true,
    },
    {
      id: rec2,
      deleted: true,
    },
  ];
};

export const exampleRecordId = (records: RecordModel[], recordIndex = 0): string =>
  records[recordIndex]?.id ?? '[RECORD-ID]';

/** Add query params to a url. */
const appendQueryParams = (url: string, queryParams: QueryParams | undefined): string => {
  if (!queryParams) return url;

  const searchParams = new URLSearchParams();

  Object.keys(queryParams).forEach((key) => {
    searchParams.set(key, queryParams[key].toString());
  });

  return `${url}?${searchParams.toString()}`;
};
