export type Colors = keyof typeof colors;

export const colors = {
  blue: {
    darker: '#0C41F3',
    base: '#3A86FF',
    washed: '#B7E3FB',
    deep: '#E7F0FE',
  },
  lightblue: {
    darker: '#00B3FA',
    base: '#4ECCFD',
    washed: '#B3EFFE',
    deep: '#E5F8FF',
  },
  green: {
    darker: '#199A27',
    base: '#3EAC40',
    washed: '#CDEECE',
    deep: '#EEF6EE',
  },
  red: {
    darker: '#F1273F',
    base: '#FF5757',
    washed: '#FEBDC7',
    deep: '#FEECEE',
  },
  orange: {
    darker: '#FF702E',
    base: '#FF9210',
    washed: '#FFD5B3',
    deep: '#FFEDE5',
  },
  yellow: {
    darker: '#FDA80D',
    base: '#FFB938',
    washed: '#FFECB3',
    deep: '#FFF2DC',
  },
  purple: {
    darker: '#673DB6',
    base: '#883CD0',
    washed: '#DFCCFF',
    deep: '#F2EAFA',
  },
  pink: {
    darker: '#CD286A',
    base: '#EC506E',
    washed: '#FFC7E7',
    deep: '#FBEAF1',
  },
  teal: {
    darker: '#00B2A8',
    base: '#17C4C4',
    washed: '#C2F0ED',
    deep: '#EBF4F4',
  },
  grey: {
    darker: '#2d2d2d',
    base: '#6A849B',
    washed: '#D7D7DB',
    deep: '#F1F2F3',
  },
};

export const baseColors = Object.values(colors).map(({ base }) => base);

const blue = ['#0C41F3', '#3A86FF', '#B7E3FB', '#0C41F3'];
const lightblue = ['#00B3FA', '#4ECCFD', '#B3EFFE', '#E5F8FF'];
const green = ['#199A27', '#3EAC40', '#CDEECE', '#EEF6EE'];
const red = ['#F1273F', '#FF5757', '#FEBDC7', '#FEECEE'];
const orange = ['#FF702E', '#FF9210', '#FFD5B3', '#FFEDE5'];
const yellow = ['#FDA80D', '#FFB938', '#FFECB3', '#FFF2DC'];
const purple = ['#673DB6', '#883CD0', '#DFCCFF', '#F2EAFA'];
const pink = ['#CD276A', '#EC506E', '#FFC7E7', '#FBEAF1'];
const teal = ['#00B2A8', '#17C4C4', '#C2F0ED', '#EBF4F4'];
const grey = ['#2d2d2d', '#6A849B', '#D7D7DB', '#F1F2F3'];

function getColorName(color: string): Colors | null {
  if (blue.includes(color)) return 'blue';
  if (green.includes(color)) return 'green';
  if (lightblue.includes(color)) return 'lightblue';
  if (red.includes(color)) return 'red';
  if (orange.includes(color)) return 'orange';
  if (yellow.includes(color)) return 'yellow';
  if (purple.includes(color)) return 'purple';
  if (pink.includes(color)) return 'pink';
  if (teal.includes(color)) return 'teal';
  if (grey.includes(color)) return 'grey';
  return null;
}

export function getWashedColor(currentColor: string): string {
  const colorName = currentColor ? getColorName(currentColor.toUpperCase()) : 'blue';
  return colorName ? colors[colorName].washed : '';
}

export function getDeepColor(currentColor: string): string {
  const colorName = currentColor ? getColorName(currentColor?.toUpperCase()) : 'blue';
  return colorName ? colors[colorName].deep : '';
}
