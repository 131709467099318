import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { main } from 'translations/main';

import { TextColumn, TextRow } from './application/ApplicationSharedStyles';
import { DocsSection, DocsSectionCode, DocsSectionText } from './DocSection';

export const ErrorCodesSection: FunctionComponent = () => (
  <DocsSection>
    <DocsSectionText>
      <h2 id="errorCodes">
        <FormattedMessage {...main.errorCodes} />
      </h2>
      <p>
        <FormattedMessage
          {...main.errorCodesBody1}
          values={{
            code: (children: string) => (
              <strong>
                <code>{children}</code>
              </strong>
            ),
          }}
        />
      </p>

      <ErrorCodeGroup>
        <h3 id="success-code">
          <FormattedMessage {...main.successCode} />
        </h3>
        <ErrorCodeRow>
          <TextRow>
            <TextColumn flexBasis="10%">
              <ErrorCodeOk>200</ErrorCodeOk>
            </TextColumn>
            <TextColumn flexBasis="40%">
              <code>OK</code>
            </TextColumn>
            <TextColumn flexBasis="50%">
              <p>
                <FormattedMessage {...main.errorCodes200} />
              </p>
            </TextColumn>
          </TextRow>
        </ErrorCodeRow>
      </ErrorCodeGroup>

      <ErrorCodeGroup>
        <h3 id="user-error-codes">
          <FormattedMessage {...main.userErrorCodes} />
        </h3>
        <ErrorCodeRow>
          <TextRow>
            <TextColumn flexBasis="10%">
              <ErrorCodeWarning>400</ErrorCodeWarning>
            </TextColumn>
            <TextColumn flexBasis="40%">
              <code>Bad Request</code>
            </TextColumn>
            <TextColumn flexBasis="50%">
              <p>
                <FormattedMessage {...main.errorCodes400} />
              </p>
            </TextColumn>
          </TextRow>
        </ErrorCodeRow>
        <ErrorCodeRow>
          <TextRow>
            <TextColumn flexBasis="10%">
              <ErrorCodeWarning>401</ErrorCodeWarning>
            </TextColumn>
            <TextColumn flexBasis="40%">
              <code>Unauthorized</code>
            </TextColumn>
            <TextColumn flexBasis="50%">
              <p>
                <FormattedMessage {...main.errorCodes401} />
              </p>
            </TextColumn>
          </TextRow>
        </ErrorCodeRow>
        <ErrorCodeRow>
          <TextRow>
            <TextColumn flexBasis="10%">
              <ErrorCodeWarning>403</ErrorCodeWarning>
            </TextColumn>
            <TextColumn flexBasis="40%">
              <code>Forbidden</code>
            </TextColumn>
            <TextColumn flexBasis="50%">
              <p>
                <FormattedMessage {...main.errorCodes403} />
              </p>
            </TextColumn>
          </TextRow>
        </ErrorCodeRow>
        <ErrorCodeRow>
          <TextRow>
            <TextColumn flexBasis="10%">
              <ErrorCodeWarning>404</ErrorCodeWarning>
            </TextColumn>
            <TextColumn flexBasis="40%">
              <code>Not Found</code>
            </TextColumn>
            <TextColumn flexBasis="50%">
              <p>
                <FormattedMessage {...main.errorCodes404} />
              </p>
            </TextColumn>
          </TextRow>
        </ErrorCodeRow>
        <ErrorCodeRow>
          <TextRow>
            <TextColumn flexBasis="10%">
              <ErrorCodeWarning>422</ErrorCodeWarning>
            </TextColumn>
            <TextColumn flexBasis="40%">
              <code>Invalid Request</code>
            </TextColumn>
            <TextColumn flexBasis="50%">
              <p>
                <FormattedMessage {...main.errorCodes422} />
              </p>
            </TextColumn>
          </TextRow>
        </ErrorCodeRow>
      </ErrorCodeGroup>

      <ErrorCodeGroup>
        <h3 id="server-error-codes">
          <FormattedMessage {...main.serverErrorCodes} />
        </h3>
        <ErrorCodeRow>
          <TextRow>
            <TextColumn flexBasis="10%">
              <ErrorCodeDanger>500</ErrorCodeDanger>
            </TextColumn>
            <TextColumn flexBasis="40%">
              <code>Internal Server Error</code>
            </TextColumn>
            <TextColumn flexBasis="50%">
              <p>
                <FormattedMessage {...main.errorCodes500} />
              </p>
            </TextColumn>
          </TextRow>
        </ErrorCodeRow>
        <ErrorCodeRow>
          <TextRow>
            <TextColumn flexBasis="10%">
              <ErrorCodeDanger>502</ErrorCodeDanger>
            </TextColumn>
            <TextColumn flexBasis="40%">
              <code>Bad Gateway</code>
            </TextColumn>
            <TextColumn flexBasis="50%">
              <p>
                <FormattedMessage {...main.errorCodes502} />
              </p>
            </TextColumn>
          </TextRow>
        </ErrorCodeRow>
        <ErrorCodeRow>
          <TextRow>
            <TextColumn flexBasis="10%">
              <ErrorCodeDanger>503</ErrorCodeDanger>
            </TextColumn>
            <TextColumn flexBasis="40%">
              <code>Service Unavailable</code>
            </TextColumn>
            <TextColumn flexBasis="50%">
              <p>
                <FormattedMessage {...main.errorCodes503} />
              </p>
            </TextColumn>
          </TextRow>
        </ErrorCodeRow>
      </ErrorCodeGroup>
    </DocsSectionText>
    <DocsSectionCode />
  </DocsSection>
);

const ErrorCodeGroup = styled.div`
  margin-bottom: 50px;
`;

const ErrorCodeRow = styled.div`
  &:first-of-type {
    margin-top: 25px;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(222, 222, 222, 0.7);
    padding-bottom: 10px;
  }
`;

const ErrorCodeOk = styled.span`
  color: green;
`;

const ErrorCodeWarning = styled.span`
  color: orange;
`;

const ErrorCodeDanger = styled.span`
  color: red;
`;
