import axios, { AxiosRequestConfig } from 'axios';

export const fetchGetWithAccountId = <T>(url: string, accountId: string): Promise<T> => {
  return fetchGet<T>(url, {
    headers: {
      'ACCOUNT-ID': accountId,
    },
  });
};

export const fetchPostWithAccountId = async <T>(url: string, accountId: string): Promise<T> => {
  const response = await axios.post<T>(
    url,
    {},
    {
      headers: { 'ACCOUNT-ID': accountId },
      withCredentials: true,
    }
  );
  return response.data;
};

export const fetchGet = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const response = await axios.get<T>(url, {
    ...config,
    withCredentials: true,
  });
  return response.data;
};
