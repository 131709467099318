import { defineMessages, MessageDescriptor } from 'react-intl';

import { Fields } from './fields';

export const FieldTypeName: Record<
  Fields,
  MessageDescriptor & { type: string; defaultMessage: string }
> = defineMessages({
  recordtitlefield: {
    id: 'recordtitlefield',
    label: 'Title Field',
    type: 'string',
    defaultMessage: `<title>{string}</title>
      <p>The Table’s title (primary) field.</p>
      <p>The title field is used as the primary label for a record, and can be configured to include user entered text or an automatically-generated value. Note that Title Fields with generated values cannot be explicitly set via the API - any value specified when the title is configured to auto-generate will be ignored.</p>`,
  },
  textfield: {
    id: 'textfield',
    label: 'Text Field',
    type: 'string',
    defaultMessage: `
      <title>{string}</title>
      <p>A single line of text.</p>`,
  },
  textnestedfield: {
    id: 'textnested',
    label: 'Text Nested Field',
    type: 'string',
    defaultMessage:
      '<p>Add nested text that can be nested, also stores a joined value of all the nested fields in the sys_root subfield. Nested values are joined by a delimiter, provided in constructor</p>',
  },
  emailfield: {
    id: 'emailfield',
    label: 'Email Field',
    type: 'string',
    defaultMessage: `<title>{arrayOfStrings}</title>
      <p>An array of strings containing email addresses.</p>
      <p>Note that SmartSuite validates email addresses, and attempting to save an invalid email via the API will result in a <linkToError400>400 error</linkToError400>. The body of the response will contain the field id and the error “Email is not valid.” Additionally, a <linkToError400>400 error</linkToError400> will be returned if you try to add multiple email addresses to an Email field that is configured to support a single value in its properties.</p>`,
  },
  addressfield: {
    id: 'addressfield',
    label: 'Address field',
    type: 'string',
    defaultMessage: `<title>Address object</title>
      <p>The Address object contains the following properties.</p>

      <p><code>{location_address}</code> <type>({string})</type>{br}
      The location’s address.</p>

      <p><code>{location_address2}</code> <type>({string})</type>{br}
      The location’s address, line 2.</p>

      <p><code>{location_city}</code> <type>({string})</type>{br}
      The location’s city.</p>

      <p><code>{location_zip}</code> <type>({string})</type>{br}
      The location’s zip or postal code.</p>

      <p><code>{location_country}</code> <type>({string})</type>{br}
      The location’s country name. See the complete list in the <linkToCountryNamesCodes>SmartSuite REST API Country Names & Codes</linkToCountryNamesCodes> help center article. Note: do not use the 2 digit country code, the full country name is required.</p>

      <p><code>{location_longitude}</code> <type>({string})</type>{br}
      Longitude is returned for addresses that have been validated by Google maps. This value should not be included in update requests to an address field.</p>

      <p><code>{location_latitude}</code> <type>({string})</type>{br}
      Latitude is returned for addresses that have been validated by Google maps. This value should not be included in update requests to an address field.</p>

      <p><code>{sys_root}</code> <type>({string})</type>{br}
      This is a system generated value that represents the “flattened” address as a string. It is ignored if passed in a record create/update request.</p>`,
  },
  textareafield: {
    id: 'textareafield',
    label: 'Text Area Field',
    type: 'string',
    defaultMessage: `<title>{string}</title>
      <p>Multiple lines of text. Text areas can store a maximum of 120,000 characters of text in a single field.</p> <p>Note that text area fields can contain line breaks but do not currently support rich text or markup. New lines are encoded as <code>{n}</code> and tabs are represented as <code>{t}</code> in the text.</p>`,
  },
  richtextareafield: {
    id: 'richtextareafield',
    label: 'Rich Textarea Field',
    type: 'richtext object',
    defaultMessage: `<title>{smartDocObject}</title>
      <p>The SmartDoc object contains three top-level properties:</p>

      <p><code>{data}</code> <type>({object})</type>{br}
      The data object has two top-level properties.</p>

      <p><code>{typeText}</code> <type>({string})</type>{br}
      This property should always be set to “doc”</p>

      <p><code>{content}</code> <type>({arrayOfContentObjects})</type>{br}
        The content object holds information about the SmartDoc’s content and structure, and has a large array of configuration options. See our help article about the Structure of SmartDoc Objects for more information.</p>

      <p><code>{html}</code> <type>({string})</type>{br}
      SmartDoc content in html format.</p>

      <p><code>{preview}</code> <type>({string})</type>{br}
      The first 500 text characters of the SmartDoc content.</p>`,
  },
  linkfield: {
    id: 'linkfield',
    label: 'Link Field',
    type: 'array of links (strings)',
    defaultMessage: `<title>{arrayOfStrings}</title>
      <p>An <code>{array}</code> of strings containing URLs.</p>
      <p>All URLs must be properly formed or the request will return a <linkToError400>400 error</linkToError400>. In case of an error the body will include the field id with a message “URL is not valid.” It is important to note that adding multiple links to a Link Field configured as “single value” does NOT return a <linkToError400>400 error</linkToError400>. Instead, values after the first value in the array are ignored and are not saved.</p>
      <p>Note that the http/https prefix is optional.</p>`,
  },
  phonefield: {
    id: 'phonefield',
    label: 'Phone Field',
    type: 'array of phone object',
    defaultMessage: `<title>{arrayOfPhoneObjects}</title>
      <p>Each phone object may contain the following properties.</p>
      <p>Note that the field can be configured to support a single phone number or multiple phone numbers. In either case the phone objects are returned as an array. The API will return a <linkToError400>400 error</linkToError400> if you try to add multiple phone objects to a field that is set to “single entry” in its properties.</p>

      <p><code>{phone_country}</code> <type>({string})</type>{br}
      The 2 character country code (Alpha-2 code) for the phone number. See the complete list in the <linkToCountryNamesCodes>SmartSuite REST API Country Names & Codes</linkToCountryNamesCodes> help center article.</p>

      <p><code>{phone_number}</code> <type>({string})</type>{br}
      The telephone number, e.g. “(913) 555-5555”{br}
      Valid separators include space, hyphen and period, as well as parenthesis. The number can also be specified without separators, e.g. “9135555555”</p>

      <p><code>{phone_extension}</code> <type>({string})</type>{br}
      The extension for the number. The specified <code>{string}</code> should contain alphanumeric characters only and no spaces. Note that it will be displayed by the field with an “x” prefix, e.g. “(913) 555-5555 x123”</p>

      <p><code>{phone_type}</code> <type>({integer})</type>{br}
      An integer representing the type of phone number from the list below:
        <offsetLeft>
          OFFICE = 1{br}
          MOBILE = 2{br}
          HOME = 4{br}
          FAX = 5{br}
          MAIN = 6{br}
          OTHER = 8
        </offsetLeft>
      </p>
      <p>Note that phone type is not currently displayed in the SmartSuite UI.</p>`,
  },
  recordidfield: {
    id: 'recordidfield',
    label: 'Record ID Field',
    type: 'object recordID',
    defaultMessage: `<title>{string}</title>
    <p>The unique identifier for the record. This value is used to reference a record for subsequent updates.</p>
    <p>This is a system-generated value and cannot be modified via the API.</p>`,
  },
  colorpickerfield: {
    id: 'colorpickerfield',
    label: 'Colorpicker Field',
    type: 'array of color object',
    defaultMessage: `<title>{arrayOfColorPickerObjects}</title>
      <p>The Color Picker object may contain the following properties.</p>

      <p>Note that the field can be configured to support a single color or multiple colors. In either case the color objects are returned as an array. The API will return a <linkToError400>400 error</linkToError400> if you try to add multiple Color Picker objects to a field that is set to “single entry” in its properties.</p>

      <p><code>{value}</code> <type>({string})</type>{br}
      The hex value of the color, e.g. “#9C6363”</p>

      <p><code>{name}</code> <type>({string})</type>{br}
      [Optional] A name for the color.</p>`,
  },
  datefield: {
    id: 'datefield',
    label: 'Date Field',
    type: 'string',
    defaultMessage: `<title>{dateObject}</title>
      <p>The Date object may have the following properties.</p>
      <p><code>{date}</code> <type>({datetime})</type>{br} A datetime value as a <type>{string}</type>, e.g. <code>“2022-08-09T19:54:21.830000Z”</code> This value can be set to <type>{null}</type> to clear the date from the field.</p>
      <p><code>{include_time}</code> <type>({boolean})</type>{br} Indicates whether the time value should be displayed in the UI.</p>`,
  },
  datetimefield: {
    id: 'datetimefield',
    label: 'Datetime Field',
    type: 'string',
    defaultMessage:
      'Add a date with an option to include time. You can choose from a range of display formats, customizing the display to best fit your Table.',
  },
  daterangefield: {
    id: 'daterangefield',
    label: 'Daterange Field',
    type: 'daterange object',
    defaultMessage: `<title>{dateRangeObject}</title>
      <p>The Date Range object may have the following properties.</p>

      <p><code>{from_date}</code> <type>({object})</type>{br}
      The from_date object has two properties:</p>

      <offsetLeft>
        <p><code>{date}</code> <type>({datetime})</type>{br}
          The datetime value of the Date Range start date. Setting this value to <code>{null}</code> clears any existing value.</p>

        <p><code>{include_time}</code> <type>({boolean})</type>{br}
        True indicates that time should be displayed.</p>
      </offsetLeft>

      <p><code>{to_date}</code> <type>({object})</type>{br}
        The to_date object has two properties:</p>

      <offsetLeft>
        <p><code>{date}</code> <type>({datetime})</type>{br}
          The datetime value of the Date Range end date. Setting this value to <code>{null}</code> clears any existing value.</p>

        <p><code>{include_time}</code> <type>({boolean})</type>{br}
        True indicates that time should be displayed.</p>
      </offsetLeft>
      `,
  },
  datetimerangefield: {
    id: 'datetimerangefield',
    label: 'Datetimerange Field',
    type: 'datetimerange object',
    defaultMessage:
      'Track time spent working on a specific task or project. This field is designed to log time spent on specific activities, along with who performed the work and any notes.',
  },
  durationfield: {
    id: 'durationfield',
    label: 'Duration Field',
    type: 'duration object',
    defaultMessage: `<title>{numberAsString}</title>
      <p>A decimal number in seconds. Note that the SmartSuite UI displays values in a configurable way, showing this total time in days, hours, minutes and seconds.</p>

      <p>Note that the API returns the current value as a string, but field updates can be made using a decimal number. Setting the field to null clears any existing duration value in the field.</p>`,
  },

  // list fields
  multipleselectfield: {
    id: 'multipleselectfield',
    label: 'Multiple Select Field',
    type: 'array string',
    defaultMessage: `<title>{arrayOfStrings}</title>
      <p>An <code>{array}</code> of strings containing the unique ids of selected list items. Sending an empty array will clear all current selections.</p>`,
  },
  singleselectfield: {
    id: 'singleselectfield',
    label: 'Single Select Field',
    type: 'string',
    defaultMessage: `<title>{string}</title>
      <p>A string containing the unique identifier for the selected item. Setting the value to <code>{null}</code> clears any existing selection.</p>`,
  },
  statusfield: {
    id: 'statusfield',
    label: 'Status Field',
    type: 'string',
    defaultMessage: `<title>{statusFieldObject}</title>
      <p>The Status Field object may have the following properties.</p>
      <p><code>{value}</code> <type>({string})</type>{br} The unique identifier of the status value item.</p>
      <p><code>{updated_on}</code> <type>({datetime})</type>{br} The datetime when the status value was last changed.</p>`,
  },
  tagsfield: {
    id: 'tagsfield',
    label: 'Tags Field',
    type: 'string',
    defaultMessage: `<title>{arrayOfStrings}</title>
      <p>An array of strings representing the unique ids of selected tag items. Setting the value to <code>{null}</code> removes any tags currently selected for the field.</p>`,
  },
  yesnofield: {
    id: 'yesnofield',
    label: 'Yesno Field',
    type: 'boolean',
    defaultMessage: `<title>{boolean}</title>
      <p>A boolean value with <code>{true}</code> representing a checked Yes value and <code>{false}</code> representing no check.</p>`,
  },
  ipaddressfield: {
    id: 'ipaddressfield',
    label: 'Ipaddress Field',
    type: 'array IP object',
    defaultMessage: `<title>{arrayOfIpAddressObjects}</title>
      <p>Each IP Address object may contain the following properties.</p>

      <p>Note that the field can be configured to support a single IP address or multiple addresses. In either case the IP Address objects are returned as an array. The API will return a <linkToError400>400 error</linkToError400> if you try to add multiple IP Address objects to a field that is set to “single entry” in its properties.</p>

      <p><code>{country_code}</code> <type>({string})</type>{br}
      [Optional] The 2 character country code (Alpha-2 code) for the phone number. See the complete list in the <linkToCountryNamesCodes>SmartSuite REST API Country Names & Codes</linkToCountryNamesCodes> help center article.</p>

      <p><code>{address}</code> <type>({string})</type>{br}
      The IPV4 or IPV6 address.</p>`,
  },
  // reference
  linkedrecordfield: {
    id: 'linkedrecordfield',
    label: 'Linked Record Field',
    type: 'linkedrecord object',
    defaultMessage: `<title>{string}</title>
      <p>Array of linked record IDs from the <app>{applicationName}</app> Table.</p>`,
  },
  userfield: {
    id: 'userfield',
    label: 'User Field',
    type: 'user object',
    defaultMessage: `<title>{arrayOfStrings}</title>
      <p>An array of strings representing the unique ids of selected Member(s). Setting this field to <code>{null}</code> will remove any selected Members from the field.</p>
      <p>Note that the API does not honor the Single/Multiple property on the Assigned To field - any number of assignees can be attached to the field via the API.</p>`,
  },
  teamfield: {
    id: 'teamfield',
    label: 'Team Field',
    type: 'team object',
    defaultMessage:
      'Assign one or more teams to collaborate on a project. You can configure which teams are available for selection and whether multiple teams can be specified by the user.',
  },
  filefield: {
    id: 'filefield',
    label: 'File Field',
    type: 'file object',
    defaultMessage: `<title>{arrayOfFilesAndImagesObjects}</title>
      <p>Each Files and Images <code>{object}</code> may have the following properties.</p>

      <p><code>{handle}</code> <type>({string})</type>{br}
      The unique identifier for the file. This is a system-generated value and cannot be changed via the API.</p>

      <p><code>{metadata}</code> <type>({object})</type>{br}
      The metadata object contains the following properties.</p>
      <offsetLeft>
        <p><code>{container}</code> <type>({string})</type>{br}
        This is a system-generated value and cannot be changed via the API.</p>

        <p><code>{filename}</code> <type>({string})</type>{br}
        The name of the attached file.</p>

        <p><code>{key}</code> <type>({string})</type>{br}
        This is a system-generated value and cannot be changed via the API.</p>

        <p><code>{mimetype}</code> <type>({string})</type>{br}
        The mime type of the attached file</p>

        <p><code>{size}</code> <type>({number})</type>{br}
        The size (in bytes) of the attached file.</p>
      </offsetLeft>

      <p><code>{transform_options}</code> <type>({object})</type>{br}
      This is a system-generated value and cannot be changed via the API.</p>

      <p><code>{file_type}</code> <type>({string})</type>{br}
      The file type of the attached file</p>

      <p><code>{created_on}</code> <type>({datetime})</type>{br}
      The datetime the field was created.</p>

      <p><code>{updated_on}</code> <type>({datetime})</type>{br}
      The datetime the files and images field was last updated.</p>

      <p>Note that files cannot be attached via the current REST API.</p>`,
  },
  signaturefield: {
    id: 'signaturefield',
    label: 'Signature Field',
    type: 'signature object',
    defaultMessage: `<title>{signatureFieldObject}</title>
      <p>The Signature Field object contains properties that vary depending on its type, which is either text or image. Text type values can be set via the API, image type must be drawn by the user using the SmartSuite UI.</p>

      <p>Text-type Signatures may contain the following properties.</p>

      <p><code>{text}</code> <type>({string})</type>{br}
      Text of the signed name.</p>

      <p><code>{drawing}</code> <type>({emptyArray})</type>{br}
      An array is returned for text type signatures that is always empty. It is not required to pass this value to the API to update the text signature.</p>`,
  },
  unknownfield: {
    id: 'unknownfield',
    label: 'Unknown Field',
    type: 'empty',
    defaultMessage: 'empty',
  },

  // number fields
  numberfield: {
    id: 'numberfield',
    label: 'Number Field',
    type: 'number',
    defaultMessage: `<title>{numberAsText}</title>
      <p>A decimal or integer number with a precision reflecting the field’s selected precision property.</p>
      <p>Note that the API returns numbers as strings, but update requests can be numeric (and is the recommended approach).</p>`,
  },
  currencyfield: {
    id: 'currencyfield',
    label: 'Currency Field',
    type: 'string',
    defaultMessage: `<title>{number}</title>
      <p>A <code>{decimal}</code> or <code>{integer}</code> number with a precision reflecting the field’s selected precision property.</p>

      <p>Note that the API returns numbers as strings, but update requests can be numeric (and is the recommended approach). The currency symbol is not returned, and should not be sent to the API in update requests (if it is included it is ignored).</p>`,
  },
  percentfield: {
    id: 'percentfield',
    label: 'Percent Field',
    type: 'string',
    defaultMessage: `<title>{number}</title>
      <p>A <code>{decimal}</code> or <code>{integer}</code> number with a precision reflecting the field’s selected precision property. Note that the percentage is a number representing the hundredth parts of a quantity, e.g. 15 percent is specified as 15, not as 0.15. Update requests should use this same approach (i.e. passing 0.15 to a percent field will set the field to 0.15%)</p>

      <p>Note that the API returns numbers as strings, but update requests can be numeric (and is the recommended approach). The percent symbol is not required in update requests, and is ignored if included in a string representation of the value.</p>`,
  },
  autonumberfield: {
    id: 'autonumberfield',
    label: 'Autonumber Field',
    type: 'number',
    defaultMessage: `<title>{number}</title>
      <p>A whole <code>{integer}</code> representing the numeric portion of the record’s autonumber value. This value is generated by the system and cannot be set via the API.</p>
      <p>If the autonumber field id is returned in an update request it is ignored by the API (no error is generated).</p>`,
  },
  votefield: {
    id: 'votefield',
    label: 'Vote Field',
    type: 'vote object',
    defaultMessage: `<title>{voteObject}</title>
      <p>The Vote object may contain the following properties.</p>

      <p><code>{total_votes}</code> <type>({integer})</type></p>

      <p><code>{votes}</code> <type>({object})</type>{br}
      The votes object has the following two properties:</p>

      <offsetLeft>
        <p><code>{user_id}</code> <type>({string})</type>{br}
        The unique id of the voting user.</p>

        <p><code>{date}</code> <type>({date})</type>{br}
        The date the user voted, formatted as YYYY-MM-DD.</p>
      </offsetLeft>

      <p>Note that each user can only vote once. Passing vote objects that contain the same unique user will result in a <linkToError400>400 error</linkToError400>, and the body of the error response will contain the error “Users can’t vote twice.”</p>`,
  },
  numbersliderfield: {
    id: 'numbersliderfield',
    label: 'Number Slider Field',
    type: 'number',
    defaultMessage: `<title>{number}</title>
      <p>A whole <code>{integer}</code> number.</p>
      <p>Note that the API does not enforce the restrictions selected in field configuration, including increment, minimum and maximum value. Setting the field’s value to <code>{null}</code> will clear the slider value.</p>`,
  },
  ratingfield: {
    id: 'ratingfield',
    label: 'Rating Field',
    type: 'number',
    defaultMessage: `<title>{number}</title>
      <p>A whole integer representing the numeric count of a rating. The value should be between zero (blank) and the scale property specified for the field.</p>

      <p>Attempting to update a Rating Field to a value outside of this permitted range will generate a <linkToError400>400 error</linkToError400>. The body of the error response will include the field id and the error “Value out of scale.”</p>`,
  },
  availabilitystatusfield: {
    id: 'availabilitystatusfield',
    label: 'Availability Status Field',
    type: 'string',
    defaultMessage: 'Allows a user to store availability status',
  },
  socialnetworkfield: {
    id: 'socialnetworkfield',
    label: 'Social Network Field',
    type: 'social network object',
    defaultMessage: `<title>{socialNetworkObject}</title>
      <p>The Social Network object may contain the following properties. The validity of each username is not checked - any text may be passed to each property.</p>

      <p>Note that excluding a property from the Social Network object will result in the value for that username being cleared from the field.</p>

      <p><code>{facebook_username}</code> <type>({string})</type>{br}
      A Facebook username.</p>

      <p><code>{instagram_username}</code> <type>({string})</type>{br}
      An Instagram username.</p>

      <p><code>{linkedin_username}</code> <type>({string})</type>{br}
      A LinkedIn username.</p>

      <p><code>{twitter_username}</code> <type>({string})</type>{br}
      A Twitter username.</p>

      <p><code>{sys_root}</code> <type>({string})</type>{br}
      This is a system generated value that represents a comma-separated list of all social network usernames as a <code>{string}</code>. It is ignored if passed in a record create/update request.</p>`,
  },
  timefield: {
    id: 'timefield',
    label: 'Time Field',
    type: 'time object',
    defaultMessage: `<title>{string}</title>
      <p>A string representing time in 24 hour format, e.g. “00:30:00” (12:30am in 12 hour time).</p>

      <p>Setting the value to <code>{null}</code> clears any existing value in the time field. Any values passed to the API that cannot be converted to a time value will generate a <linkToError400>400 error</linkToError400>, with the response body text containing the error “[value] can’t be converted to time”</p>`,
  },
  firstcreatedfield: {
    id: 'firstcreatedfield',
    label: 'First Created Field',
    type: 'firstcreated object',
    defaultMessage: `<title>{firstCreatedObject}</title>
      <p>The First Created object has the following properties.</p>

      <p><code>{by}</code> <type>({string})</type>{br}
      The unique identifier of the user who created the record.</p>

      <p><code>{on}</code> <type>({datetime})</type>{br}
      The date and time the record was created.</p>

      <p>This is a system-generated value that cannot be modified via the API.</p>`,
  },
  lastupdatedfield: {
    id: 'lastupdatedfield',
    label: 'Last Updated Field',
    type: 'lastupdate object',
    defaultMessage: `<title>{lastUpdatedObject}</title>
      <p>The Last Updated object has the following properties.</p>

      <p><code>{by}</code> <type>({string})</type>{br}
      The unique identifier of the user who last updated the record.</p>

      <p><code>{on}</code> <type>({datetime})</type>{br}
      The date and time the record was last updated.</p>

      <p>This is a system-generated value that cannot be modified via the API.</p>`,
  },
  duedatefield: {
    id: 'duedatefield',
    label: 'Due Date Field',
    type: 'duedate object',
    defaultMessage: `
      <title>{dueDateObject}</title>
      <p>The Due Date object may have the following properties.</p>

      <p><code>{from_date}</code> <type>({object})</type>{br}
      The from_date object has two properties:</p>

      <offsetLeft>
        <p><code>{date}</code> <type>({datetime})</type>{br}
        The datetime value of the Due Date’s start date. Setting this value to null clears any existing value.</p>

        <p><code>{include_time}</code> <type>({boolean})</type>{br}
        True indicates that time should be displayed.</p>
      </offsetLeft>

      <p><code>{to_date}</code> <type>({object})</type>{br}
      The to_date object has two properties:</p>

      <offsetLeft>
        <p><code>{date}</code> <type>({datetime})</type>{br}
        The datetime value of the Due Date’s end date. Setting this value to null clears any existing value.</p>

        <p><code>{include_time}</code> <type>({boolean})</type>{br}
        True indicates that time should be displayed.</p>
      </offsetLeft>

      <p><code>{is_overdue}</code> <type>({boolean})</type>{br}
      True indicates that the Due Date is overdue. This is a system property and cannot be set via the API.</p>

      <p><code>{status_is_completed}</code> <type>({boolean})</type>{br}
      True indicates that the Due Date’s linked Status field  is in a “complete” state. This is a system property and cannot be set via the API.</p>

      <p><code>{status_updated_on}</code> <type>({datetime})</type>{br}
      The datetime the Due Date’s linked Status field was last updated. This is a system property and cannot be set via the API.</p>`,
  },
  percentcompletefield: {
    id: 'percentcompletefield',
    label: 'Percent Complete Field',
    type: 'number',
    defaultMessage: `<title>{number}</title>
    <p>A whole <code>{integer}</code> number. Note that the percentage is a number representing the hundredth parts of a quantity, e.g. 15 percent is specified as 15, not as 0.15. Update requests should use this same approach (i.e. passing 0.15 to a percent field will set the field to 0.15%)</p>

    <p>Only the whole number is used when performing an update request to a percent complete field, decimals are ignored. Attempting to update the percent complete value to a number less than 0 or greater than 100 will cause the API to ignore the request (the prior value is not modified, and no error is returned).</p>`,
  },
  checklistfield: {
    id: 'checklistfield',
    label: 'Checklist Field',
    type: 'checklist object',
    defaultMessage: `<title>{itemsObjectContainingAnArrayOfChecklistItemObjects}</title>
      <p>The checklist field contains a parent “items” object that holds an array of Checklist items. Individual checklist items objects may have the following properties.</p>

      <p><code>{id}</code> <type>({string})</type></p>

      <p><code>{content}</code> <type>({object})</type>{br}
      The content object may have the following properties.</p>

      <offsetLeft>
        <p><code>{data}</code> <type>({object})</type>{br}
        The data object contains two properties.</p>

        <offsetLeft>
          <p><code>{typeText}</code> <type>({string})</type>{br}
          The value of this property should always be set to “doc”</p>

          <p><code>{content}</code> <type>({object})</type>{br}
          The content object at this level contains three properties.</p>

          <offsetLeft>
            <p><code>{typeText}</code> <type>({string})</type>{br}
            This property should always be set to “paragraph”</p>

            <p><code>{attrs}</code> <type>({string})</type>{br}
            This property is returned by the API but can be omitted in update requests.</p>

            <p><code>{content}</code> <type>({string})</type>{br}
            This property is an object containing two properties.
              <offsetLeft>
                <code>{typeText}</code> <type>({string})</type> - This property should always have a value of “text”{br}
                <code>{text}</code> <type>({string})</type> - The text of the line item.
              </offsetLeft>
            </p>
          </offsetLeft>
        </offsetLeft>

        <p><code>{html}</code> <type>({html})</type>{br}
        This property returns the line item as html and can be omitted from update requests.</p>

        <p><code>{preview}</code> <type>({string})</type>{br}
        This property always returns an empty <code>{string}</code> and can be omitted from update requests.</p>
      </offsetLeft>

      <p><code>{completed}</code> <type>({boolean})</type>{br}
      This property indicates that the item is complete with a <code>{true}</code> value, or incomplete with a <code>{false}</code> value.</p>

      <p><code>{assignee}</code> <type>({string})</type>{br}
      The assigned Member’s unique id. Set to <code>{null}</code> if there is no assignee for the item.</p>

      <p><code>{due_date}</code> <type>({date})</type>{br}
      The due date in <code>YYYY-MM-DD</code> format. Set to <code>{null}</code> if there is no due date for the item.</p>

      <p><code>{completed_at}</code> <type>({datetime})</type>{br}
      A datetime value representing when the item was completed. This value must be <code>{null}</code> if the completed property is <code>{false}</code>.</p>`,
  },
  countfield: {
    id: 'countfield',
    label: 'Count Field',
    type: 'count object',
    defaultMessage: `<title>{numberAsText}</title>
      <p>An integer number reflecting the number of linked items, possibly with a filter applied.</p>

      <p>Note that the API returns numbers as strings for this field. Count is a system-generated value and cannot be updated via the API.</p>`,
  },
  rollupfield: {
    id: 'rollupfield',
    label: 'Rollup Field',
    type: 'rollup object',
    defaultMessage: `<title>{numberAsText}</title>
      <p>A <code>{decimal}</code> or <code>{integer}</code> reflecting the aggregate value for the rolled-up field data. The field’s configured aggregation (SUM, AVG etc) is applied.</p>

      <p>Note that the API returns numbers as strings for this field. Rollup is a system-generated value and cannot be updated via the API.</p>`,
  },
  lookupfield: {
    id: 'lookupfield',
    label: 'Lookup Field',
    type: 'lookup object',
    defaultMessage: `<title>{arrayOfStringsOrNumbersOrDatesOrObjects}</title>
      <p>The lookup field returns an <code>{array}</code> populated with the values assigned to the records linked - via the lookup field’s configuration - the current record. Return type will correspond to that of the looked-up field.</p>

      <p>This is a system-generated value and cannot be updated via the API.</p>`,
  },
  formulafield: {
    id: 'formulafield',
    type: 'formula object',
    label: 'Formula Field',
    defaultMessage: `<title>{numberOrDatetimeOrStringFormula}</title>
      <p>Computed value: [FORMULA]</p>
      <p>Note that this is a system-generated value and cannot be modified via the API.</p>`,
  },
  timetrackingfield: {
    id: 'timetrackingfield',
    label: 'Time Tracking Field',
    type: 'timetracking object',
    defaultMessage: `<title>{timeTrackingLogObject}</title>
      <p>The Time Tracking Log object may contain the following properties.</p>

      <p><code>{total_duration}</code> <type>({number})</type>{br}
      Total time logged in the Time Tracking Log field, in seconds.</p>

      <p><code>{time_track_logs}</code> <type>({arrayOfTimeTrackLogsObjects})</type>{br}
      Each time_track_logs object may have the following properties:</p>
      <offsetLeft>
        <p><code>{user_id}</code> <type>({string})</type>{br}
        The user’s unique id.</p>

        <p><code>{date_time}</code> <type>({datetime})</type>{br}
        The datetime value represents the time the entry was made.</p>

        <p><code>{duration}</code> <type>({number})</type>{br}
        The log entry’s duration in seconds.</p>

        <p><code>{note}</code> <type>({string})</type>{br}
        Text of the note associated with the time entry.</p>

        <p><code>{time_range}</code> <type>({timeRangeObject})[Optional]</type>{br}
          <offsetLeft>
            <p>The Date Range object may have the following properties.</p>

            <p><code>{from_date}</code> <type>({object})</type>{br}
            The from_date object has two properties:</p>

            <offsetLeft>
              <p><code>{date}</code> <type>({datetime})</type>{br}
                The datetime value of the Date Range start date. Setting this value to <code>{null}</code> clears any existing value.</p>

              <p><code>{include_time}</code> <type>({boolean})</type>{br}
              True indicates that time should be displayed.</p>
            </offsetLeft>

            <p><code>{to_date}</code> <type>({object})</type>{br}
              The to_date object has two properties:</p>

            <offsetLeft>
              <p><code>{date}</code> <type>({datetime})</type>{br}
                The datetime value of the Date Range end date. Setting this value to <code>{null}</code> clears any existing value.</p>

              <p><code>{include_time}</code> <type>({boolean})</type>{br}
              True indicates that time should be displayed.</p>
            </offsetLeft>
          </offsetLeft>
        </p>

      </offsetLeft>`,
  },
  fullnamefield: {
    id: 'fullnamefield',
    label: 'Fullname Field',
    type: 'string',
    defaultMessage: `<title>{fullNameObject}</title>
    <p>The Full Name object may contain the following properties.</p>

    <p><code>{first_name}</code> <type>({string})</type>{br}
    The subject’s first name, e.g. “John”</p>

    <p><code>{middle_name}</code> <type>({string})</type>{br}
    The subject’s middle name. Note that this value is stored but is not currently displayed in the SmartSuite UI.</p>

    <p><code>{last_name}</code> <type>({string})</type>{br}
    The subject’s last name, e.g. “Smith”</p>

    <p><code>{sys_root}</code> <type>({string})</type>{br}
    This is a system generated value that represents the full name as a <code>{string}</code>. It is ignored if passed in a record create/update request.</p>`,
  },
  commentscountfield: {
    id: 'commentscountfield',
    label: 'Comments Count Field',
    type: 'number',
    defaultMessage: `<title>{number}</title>
    <p>A whole integer that represents the number of comments in an “open” state currently associated with the record.</p>
    <p>This is a system-generated value that cannot be modified via the API.</p>`,
  },
  lastvisitedfield: {
    id: 'lastvisitedfield',
    label: 'Last Visited Field',
    type: 'Table ID',
    defaultMessage: 'Records the last visited Table in a solution.',
  },
  subitemsfield: {
    id: 'subitemsfield',
    label: 'Sub-Items Field',
    type: 'Record Array',
    defaultMessage: `<title>{subItemsObject}</title>
    <p>The Sub-Items object has the following properties.</p>

    <p><code>{count}</code> <type>({number})</type>{br}
    A whole integer representing the current number of sub-item records contained in the field.</p>

    <p><code>{items}</code> <type>({arrayOfSubItemRecordObjects})</type></p>

    <p>Each sub-item record object has the properties listed below. It will additionally include properties that represent fields that have been added to the sub-item. They will have structures consistent with standard SmartSuite fields.</p>

    <offsetLeft>
      <p><code>{id}</code> <type>({string})</type>{br}
      The unique id of the sub-item record.</p>

      <p><code>{first_created}</code> <type>({object})</type></p>
      <p><code>{last_created}</code> <type>({object})</type></p>
      <p>The first_created and last_updated share the same properties:</p>
      <offsetLeft>
        <p><code>{by}</code> <type>({string})</type>{br}
        The unique Id of the creating/updating user</p>

        <p><code>{on}</code> <type>({datetime})</type>{br}
        The datetime representing the creation/last update of the record</p>
      </offsetLeft>
    </offsetLeft>`,
  },
  buttonfield: {
    id: 'buttonfield',
    label: 'Button Field',
    type: 'null or string',
    defaultMessage: `<title>{string}</title>
    <p>A URL that is either statically defined or generated by a formula.</p>
    <p>Button fields configured with a static URL value will return this value for each record. Buttons configured with a URL Formula return the calculated URL value specific to the individual record and its values.</p>
    <p>Note that both static and URL Formula button fields are read-only and the field's value cannot be set via the records endpoint.</p>`,
  },
});
