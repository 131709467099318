import { useMemo, useContext } from 'react';
import * as filestack from 'filestack-js';

import { CurrentUserContext } from './CurrentUserContext';

const fileStackKey = process.env.REACT_APP_FILE_STACK_KEY || '';

/** Initialize the Filestack client using the user's security settings. */
export const useFilestack = (): filestack.Client => {
  const currentUser = useContext(CurrentUserContext);

  return useMemo(
    () =>
      filestack.init(fileStackKey, {
        security: currentUser?.file_security,
      }),
    [currentUser]
  );
};
