import debounce from 'lodash/debounce';
import React from 'react';

import { ApplicationModel } from 'lib/models/application.model';
import { SolutionModel } from 'lib/models/solution.model';

import { ApplicationDocs } from './application/ApplicationDocs';
import { AuthenticationSection } from './AuthenticationSection';
import { IntroductionSection } from './IntroductionSection';
import { RateLimitsSection } from './RateLimitsSection';
import { MetaDataSection } from './MetaDataSection';
import { CommonErrorsSection } from './CommonErrorsSection';
import { FaqSection } from './FaqSection';
import { ErrorCodesSection } from './ErrorCodesSection';

const introductionBottomOffset = 178;
const rateLimitsBottomOffset = 332;
const authenticationBottomOffset = 560;
const docsBodyOffsetLimit = 1000;

export const resolveStaticSidebarLink = (onActiveApplication: Function): void => {
  const yscroll = window.pageYOffset;
  if (yscroll >= docsBodyOffsetLimit) return;

  const appModel: any = {
    slug: 'introduction',
  };

  if (yscroll >= 0 && yscroll < introductionBottomOffset) {
    appModel.slug = 'introduction';
    onActiveApplication(appModel);
  }
  if (yscroll >= introductionBottomOffset && yscroll < rateLimitsBottomOffset) {
    appModel.slug = 'rateLimits';
    onActiveApplication(appModel);
  }
  if (yscroll >= rateLimitsBottomOffset && yscroll < authenticationBottomOffset) {
    appModel.slug = 'authentication';
    onActiveApplication(appModel);
  }
};

export interface DocsBodyProps {
  accountId: string;
  apiToken: string;
  solution: SolutionModel;
  applications: ApplicationModel[] | undefined;
  onActiveApplication: (application: ApplicationModel) => void;
}

export const DocsBody: React.FunctionComponent<DocsBodyProps> = ({
  accountId,
  apiToken,
  solution,
  applications,
  onActiveApplication,
}) => {
  React.useEffect(() => {
    const onScroll = debounce(() => resolveStaticSidebarLink(onActiveApplication), 100);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onActiveApplication]);

  return (
    <main>
      <IntroductionSection solution={solution} />
      <MetaDataSection />
      <RateLimitsSection />
      <CommonErrorsSection />
      <FaqSection />
      <AuthenticationSection />

      {applications?.map((application) => (
        <ApplicationDocs
          key={application.id}
          accountId={accountId}
          apiToken={apiToken}
          application={application}
          onActiveApplication={onActiveApplication}
        />
      ))}

      <ErrorCodesSection />
    </main>
  );
};
