import React from 'react';

import { ApplicationModel } from 'lib/models/application.model';
import { RecordModel } from 'lib/models/record.model';

export interface ApplicationContextValue {
  application: ApplicationModel;
  records: RecordModel[];
  accountId: string;
  apiToken: string;
}

export const ApplicationContext = React.createContext<ApplicationContextValue>({
  application: {} as ApplicationModel,
  records: [],
  accountId: '',
  apiToken: '',
});
