import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { SyntaxHighlighter } from 'components/base/SyntaxHighlighter';
import { getFieldSampleData } from 'lib/application-docs.helper';
import { FieldTypeName } from 'lib/fieldTypeDictionary';
import { AppStructureField } from 'lib/models/application.model';
import { RecordModel } from 'lib/models/record.model';
import { appSection } from 'translations/appSection';

import { ApplicationNamePill, DocsSection, DocsSectionCode, DocsSectionText } from '../DocSection';
import { TextColumn, TextRow } from './ApplicationSharedStyles';
import { FieldParams } from './FieldParams';
import { ApplicationContext } from './ApplicationContext';

interface ApplicationFieldRowProps {
  field: AppStructureField;
  records: RecordModel[] | undefined;
}

export const ApplicationFieldRow: React.FunctionComponent<ApplicationFieldRowProps> = ({
  field,
  records,
}) => {
  const { application } = useContext(ApplicationContext);

  const examples = useMemo(
    () => (records ? getFieldSampleData(field, records) : null),
    [field, records]
  );

  const fieldNameText = FieldTypeName[field.field_type] || FieldTypeName.unknownfield;

  return (
    <DocsSection>
      <DocsSectionText>
        <TextRow>
          <TextColumn flexBasis="25%">{field.label}</TextColumn>
          <TextColumn flexBasis="25%">
            <code>{field.slug}</code>
          </TextColumn>
          <TextColumn flexBasis="50%">
            <DescriptionColumnFieldDescription>
              <FormattedMessage
                {...fieldNameText}
                values={{
                  applicationName: application.name,
                  t: '\\t',
                  n: '\\n',
                  string: 'string',
                  object: 'object',
                  array: 'array',
                  integer: 'integer',
                  null: 'null',
                  boolean: 'boolean',
                  false: 'false',
                  true: 'true',
                  number: 'number',
                  text: 'text',
                  decimal: 'decimal',
                  arrayOfStrings: 'array of strings',
                  arrayOfContentObjects: 'array of content objects',
                  arrayOfPhoneObjects: 'array of phone objects',
                  arrayOfColorPickerObjects: 'array of Color Picker objects',
                  dateRangeObject: 'Date Range object',
                  dateObject: 'Date object',
                  statusFieldObject: 'Status Field object',
                  arrayOfIpAddressObjects: 'array of IP Address objects',
                  arrayOfFilesAndImagesObjects: 'array of Files and Images objects',
                  itemsObjectContainingAnArrayOfChecklistItemObjects:
                    'items object containing an array of Checklist item objects',
                  arrayOfStringsOrNumbersOrDatesOrObjects:
                    'array of strings or numbers or dates or objects',
                  arrayOfTimeTrackLogsObjects: 'array of time_track_logs objects',
                  timeTrackingLogObject: 'Time Tracking Log object',
                  subItemsObject: 'Sub-Items object',
                  arrayOfSubItemRecordObjects: 'array of sub-item record objects',
                  numberAsString: 'Number (as string)',
                  numberOrDatetimeOrStringFormula:
                    'Number or datetime or string (display the one applicable to the specific formula)',
                  signatureFieldObject: 'Signature Field object',
                  voteObject: 'Vote object',
                  socialNetworkObject: 'Social Network object',
                  dueDateObject: 'Due Date object',
                  smartDocObject: 'SmartDoc object',
                  numberAsText: 'Number (as text)',
                  firstCreatedObject: 'First Created object',
                  lastUpdatedObject: 'Last Updated object',
                  timeRangeObject: 'time_range object',
                  fullNameObject: 'Full Name object',
                  address: 'address',
                  location_address: 'location_address',
                  location_address2: 'location_address2',
                  location_city: 'location_city',
                  location_zip: 'location_zip',
                  location_country: 'location_country',
                  location_longitude: 'location_longitude',
                  location_latitude: 'location_latitude',
                  phone_country: 'phone_country',
                  phone_number: 'phone_number',
                  phone_extension: 'phone_extension',
                  phone_type: 'phone_type',
                  country_code: 'country_code',
                  sys_root: 'sys_root',
                  data: 'data',
                  value: 'value',
                  name: 'name',
                  typeText: 'type',
                  content: 'content',
                  html: 'html',
                  preview: 'preview',
                  date: 'date',
                  datetime: 'datetime',
                  from_date: 'from_date',
                  to_date: 'to_date',
                  date_time: 'date_time',
                  include_time: 'include_time',
                  created_on: 'created_on',
                  updated_on: 'updated_on',
                  is_overdue: 'is_overdue',
                  status_is_completed: 'status_is_completed',
                  status_updated_on: 'status_updated_on',
                  handle: 'handle',
                  metadata: 'metadata',
                  container: 'container',
                  filename: 'filename',
                  key: 'key',
                  mimetype: 'mimetype',
                  size: 'size',
                  transform_options: 'transform_options',
                  file_type: 'file_type',
                  drawing: 'drawing',
                  emptyArray: 'empty array',
                  total_votes: 'total_votes',
                  votes: 'votes',
                  user_id: 'user_id',
                  facebook_username: 'facebook_username',
                  instagram_username: 'instagram_username',
                  linkedin_username: 'linkedin_username',
                  twitter_username: 'twitter_username',
                  by: 'by',
                  on: 'on',
                  id: 'id',
                  attrs: 'attrs',
                  completed: 'completed',
                  assignee: 'assignee',
                  due_date: 'due_date',
                  completed_at: 'completed_at',
                  total_duration: 'total_duration',
                  time_track_logs: 'time_track_logs',
                  duration: 'duration',
                  note: 'note',
                  time_range: 'time_range',
                  first_name: 'first_name',
                  middle_name: 'middle_name',
                  last_name: 'last_name',
                  count: 'count',
                  items: 'items',
                  first_created: 'first_created',
                  last_created: 'last_created',
                  title: (children: string) => <DescriptionTitle>{children}</DescriptionTitle>,
                  type: (children: string) => <DescriptionType>{children}</DescriptionType>,
                  offsetLeft: (children: string) => <OffsetLeft>{children}</OffsetLeft>,
                  code: (children: string) => <code>{children}</code>,
                  app: (children: string) => <ApplicationNamePill>{children}</ApplicationNamePill>,
                  p: (children: string) => <p>{children}</p>,
                  br: <br />,
                  linkToError400: (children: string) => <a href="#user-error-codes">{children}</a>,
                  linkToCountryNamesCodes: (children: string) => (
                    <a
                      href="http://help.smartsuite.com/en/articles/6455502-rest-api-country-names-codes"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {children}
                    </a>
                  ),
                }}
              />
            </DescriptionColumnFieldDescription>
          </TextColumn>
        </TextRow>
        <FieldParams field={field} />
      </DocsSectionText>

      <DocsSectionCode>
        <ExampleValues>
          <FormattedMessage {...appSection.exampleValue} />
        </ExampleValues>

        {examples ? (
          <SyntaxHighlighter>{examples}</SyntaxHighlighter>
        ) : (
          <LoadingText>
            <FormattedMessage {...appSection.loadingMessage} />
          </LoadingText>
        )}
      </DocsSectionCode>
    </DocsSection>
  );
};

const DescriptionTitle = styled.div`
  margin-bottom: 10px;

  font-size: inherit;
  line-height: 140%;
  font-family: ${({ theme }) => theme.fonts.monospace}
  color: ${(props) => props.theme.fieldTypeColor};
`;

const DescriptionType = styled.span`
  font-size: 90%;
  font-family: ${({ theme }) => theme.fonts.monospace}
  color: ${(props) => props.theme.fieldTypeColor};
`;

const OffsetLeft = styled.div`
  position: relative;
  padding-left: 20px;

  &:before {
    content: ' ';

    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 2px;

    width: 1px;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const DescriptionColumnFieldDescription = styled.div`
  padding-bottom: 5px;
  display: block;
`;

export const ExampleValues = styled.div`
  color: #999;
  margin-top: 12px;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 4px;
  background: #30303b;
`;

export const LoadingText = styled.p`
  color: #ccc;
  font-size: 13px;
`;
