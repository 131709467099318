import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { NoMatch } from 'components/base/NotFound';
import { DocsContainer } from 'components/docs/DocsContainer';

export function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/docs/:locale/:account/solution/:solution" element={<DocsContainer />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}
