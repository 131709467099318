import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { main } from 'translations/main';

import { DocsSection, DocsSectionCode, DocsSectionText } from './DocSection';

export const FaqSection: FunctionComponent = () => (
  <DocsSection>
    <DocsSectionText>
      <h2 id="faq">
        <FormattedMessage {...main.faq} />
      </h2>

      <FaqEntry>
        <h3 id="faqQuestion1">
          <FormattedMessage {...main.faqQuestion1} />
        </h3>
        <p>
          <FormattedMessage {...main.faqAnswer1} />
        </p>
      </FaqEntry>

      <FaqEntry>
        <h3 id="faqQuestion2">
          <FormattedMessage {...main.faqQuestion2} />
        </h3>
        <p>
          <FormattedMessage {...main.faqAnswer2} />
        </p>
      </FaqEntry>

      <FaqEntry>
        <h3 id="faqQuestion3">
          <FormattedMessage {...main.faqQuestion3} />
        </h3>
        <p>
          <FormattedMessage {...main.faqAnswer3} />
        </p>
      </FaqEntry>

      <FaqEntry>
        <h3 id="faqQuestion4">
          <FormattedMessage {...main.faqQuestion4} />
        </h3>
        <p>
          <FormattedMessage {...main.faqAnswer4} />
        </p>
      </FaqEntry>

      <FaqEntry>
        <h3 id="faqQuestion5">
          <FormattedMessage {...main.faqQuestion5} />
        </h3>
        <p>
          <FormattedMessage {...main.faqAnswer5} />
        </p>
      </FaqEntry>

      <FaqEntry>
        <h3 id="faqQuestion6">
          <FormattedMessage {...main.faqQuestion6} />
        </h3>
        <p>
          <FormattedMessage {...main.faqAnswer6} />
        </p>
      </FaqEntry>
    </DocsSectionText>
    <DocsSectionCode />
  </DocsSection>
);

const FaqEntry = styled.div`
  margin: 0;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &:first-of-type {
    margin-top: 10px;
  }

  h3 {
    margin-bottom: 10px;
  }
`;
