import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { SyntaxHighlighter } from 'components/base/SyntaxHighlighter';
import { createCurlRequestExample, createRecordExample, exampleRecordId } from 'lib/examples';
import { getRecordUrl } from 'lib/urls';
import { appSection } from 'translations/appSection';

import {
  ApplicationNamePill,
  CodeSectionTitle,
  DocsSection,
  DocsSectionCode,
  DocsSectionText,
} from '../DocSection';
import { ApplicationContext } from './ApplicationContext';

export interface RetrieveRecordSectionProps {}

export const RetrieveRecordSection: React.FunctionComponent<RetrieveRecordSectionProps> =
  React.memo(() => {
    const { application, records, accountId, apiToken } = useContext(ApplicationContext);

    const curlExample = createCurlRequestExample(
      'GET',
      getRecordUrl(application.id, exampleRecordId(records)),
      accountId,
      apiToken
    );

    return (
      <DocsSection>
        <DocsSectionText>
          <h3 id={`${application.slug}-retrieve`}>
            <FormattedMessage id="retrieve-record-title" defaultMessage="Retrieve a record" />
          </h3>
          <p>
            <FormattedMessage
              {...appSection.retrieveRecordText1}
              values={{
                strong: (text: string) => <strong>{text}</strong>,
                b: (children: string) => <ApplicationNamePill>{children}</ApplicationNamePill>,
                applicationName: application.name,
              }}
            />
          </p>
          <p>
            <code>
              {process.env.REACT_APP_API_DOMAIN}/api/v1/applications/<strong>[Table Id]</strong>
              /records/
              <strong>[Record Id]</strong>/
            </code>
            <ul>
              <li>
                <FormattedMessage {...appSection.retrieveRecordNotes1} />
              </li>
              <li>
                <FormattedMessage {...appSection.retrieveRecordNotes2} />
              </li>
            </ul>
          </p>

          <h3 id={`${application.slug}-hydrating`}>
            <FormattedMessage id="hydrating-record-title" defaultMessage="Hydrating Record" />
          </h3>
          <p>
            <FormattedMessage {...appSection.retrieveRecordHydrating1} />
          </p>
          <p>
            <code>?hydrated=true</code>
          </p>
          <p>
            <FormattedMessage {...appSection.retrieveRecordHydrating2} />

            <ol>
              <li>
                <FormattedMessage id="hydrating-record-1" defaultMessage="Single Select" />
              </li>
              <li>
                <FormattedMessage id="hydrating-record-2" defaultMessage="Multiple Select" />
              </li>
              <li>
                <FormattedMessage id="hydrating-record-3" defaultMessage="Status" />
              </li>
              <li>
                <FormattedMessage id="hydrating-record-4" defaultMessage="First Created" />
              </li>
              <li>
                <FormattedMessage id="hydrating-record-5" defaultMessage="Last Updated" />
              </li>
              <li>
                <FormattedMessage id="hydrating-record-6" defaultMessage="Assigned To" />
              </li>
              <li>
                <FormattedMessage id="hydrating-record-7" defaultMessage="Tags" />
              </li>
              <li>
                <FormattedMessage id="hydrating-record-8" defaultMessage="Vote" />
              </li>
              <li>
                <FormattedMessage id="hydrating-record-9" defaultMessage="Time Tracking Log" />
              </li>
              <li>
                <FormattedMessage id="hydrating-record-10" defaultMessage="Checklist" />
              </li>
              <li>
                <FormattedMessage id="hydrating-record-11" defaultMessage="Lookup" />
              </li>
            </ol>
          </p>
        </DocsSectionText>
        <DocsSectionCode>
          <CodeSectionTitle>
            <FormattedMessage {...appSection.exampleRequest} />
          </CodeSectionTitle>

          <SyntaxHighlighter language="bash">{curlExample}</SyntaxHighlighter>

          <CodeSectionTitle>
            <FormattedMessage {...appSection.exampleResponse} />
          </CodeSectionTitle>

          <SyntaxHighlighter language="json">
            {JSON.stringify(createRecordExample(application), null, 2)}
          </SyntaxHighlighter>
        </DocsSectionCode>
      </DocsSection>
    );
  });
