import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';

import { File } from 'lib/models/file.model';
import { colors, baseColors } from 'lib/colors';

import { FileStackImage } from './FilestackImage';

interface AvatarProps {
  color?: string;
  file?: File;
  name?: string;
  className?: string;
}

const transformOptions = { resize: { width: 48, height: 48 }, rounded_corners: { radius: 48 } };

export const Avatar: FunctionComponent<AvatarProps> = ({ file, name, color = '', className }) => {
  const initials = name ? getInitials(name) : '';
  return (
    <AvatarCircle color={color} className={className}>
      {initials && <AvatarInitials>{initials}</AvatarInitials>}
      {file && <AvatarImage file={file} transformOptions={transformOptions} />}
    </AvatarCircle>
  );
};

export function getInitials(name: string): string {
  if (!name) return '';

  return name
    .split(' ')
    .map((n: string) => n[0])
    .join('');
}

export function getColor(name: string): string {
  return name
    ? baseColors[
        name
          .split('')
          .map((c) => c.charCodeAt(0))
          .reduce((prev, curr) => prev + curr, 0) % baseColors.length
      ]
    : '';
}

const AvatarCircle = styled.div<{ color: string }>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  width: 44px;
  min-width: 44px;
  height: 44px;

  background-color: ${({ color }) => color || colors.grey.base};

  &:after {
    content: ' ';

    z-index: 1;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;

    display: block;
    border: 1px solid #fff;

    border-radius: 50%;
  }
`;

const AvatarImage = styled(FileStackImage)`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

const AvatarInitials = styled.div`
  font-weight: 700;
  line-height: 100%;
  color: white;
  text-transform: uppercase;
  font-size: 15px;
`;
