import { SolutionModel } from './models/solution.model';
import { colors } from './colors';

export interface Theme {
  fieldTypeColor: string;
  projectColor: string;
  solutionColor: string;
  solutionColorWashed: string;

  fonts: {
    body: string;
    monospace: string;
  };
}

/** Set up the Styled Components theme. */
export const makeTheme = (solution?: SolutionModel): Theme => ({
  fieldTypeColor: '#feb938',
  projectColor: '#6bb1f3',
  solutionColor: solution?.logo_color ?? colors.blue.base,
  solutionColorWashed: colors.blue.washed,

  fonts: {
    body: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
    monospace: 'Consolas, Menlo, Courier, monospace;',
  },
});
