import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { main } from 'translations/main';

import { DocsSection, DocsSectionCode, DocsSectionText } from './DocSection';

export const MetaDataSection: FunctionComponent = () => (
  <DocsSection>
    <DocsSectionText>
      <h2 id="metaData">
        <FormattedMessage {...main.metaDada} />
      </h2>
      <p>
        <FormattedMessage
          {...main.metaDataBody1}
          values={{
            link: (children: string) => (
              <a
                href="https://help.smartsuite.com/en/articles/6458532-metadata-api-documentation"
                target="_blank"
                rel="noreferrer"
              >
                {children}
              </a>
            ),
          }}
        />
      </p>
    </DocsSectionText>
    <DocsSectionCode />
  </DocsSection>
);
