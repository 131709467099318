import React from 'react';
import { FormattedMessage } from 'react-intl';

import { main } from 'translations/main';

import { DocsSection, DocsSectionCode, DocsSectionText } from './DocSection';

export const AuthenticationSection: React.FunctionComponent = () => (
  <DocsSection>
    <DocsSectionText>
      <h2 id="authentication">
        <FormattedMessage {...main.authentication} />
      </h2>
      <p>
        <FormattedMessage {...main.authenticationBody1} />
      </p>
      <p>
        <strong>
          <FormattedMessage {...main.authenticationBody2} />
        </strong>
      </p>
      <p>
        <FormattedMessage
          {...main.authenticationBody3}
          values={{
            code: (children: string) => (
              <strong>
                <code>{children}</code>
              </strong>
            ),
          }}
        />
      </p>
      <p>
        <code>KEY</code>:<code>VALUE</code>
        <ul>
          <li>
            <code>
              Authorization: Token <strong>API_KEY_HERE</strong>
            </code>
          </li>
          <li>
            <code>
              Account-Id: <strong>WORKSPACE_ID_HERE</strong>
            </code>
          </li>
        </ul>
      </p>
      <p>
        <FormattedMessage
          {...main.authenticationBody4}
          values={{
            link: (children: string) => (
              <a href="https://app.smartsuite.com/" target="_blank" rel="noreferrer">
                {children}
              </a>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage
          {...main.authenticationBody5}
          values={{
            example: (children: string) => (
              <code>
                https://app.smartsuite.com/<strong>sv25cxf2</strong>/solution/62c4b…
              </code>
            ),
          }}
        />
      </p>
    </DocsSectionText>
    <DocsSectionCode />
  </DocsSection>
);
