import React from 'react';
import { FormattedMessage } from 'react-intl';

import { main } from 'translations/main';

import { DocsSection, DocsSectionCode, DocsSectionText } from './DocSection';

export const RateLimitsSection: React.FunctionComponent = () => (
  <DocsSection>
    <DocsSectionText>
      <h2 id="rateLimits">
        <FormattedMessage {...main.rateLimits} />
      </h2>
      <p>
        <FormattedMessage {...main.rateLimitsBodyRate1} />
      </p>
      <p>
        <FormattedMessage {...main.rateLimitsBodyRate2} />
      </p>
    </DocsSectionText>
    <DocsSectionCode />
  </DocsSection>
);
