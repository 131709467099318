import React, { useMemo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { SyntaxHighlighter } from 'components/base/SyntaxHighlighter';
import { createCurlRequestExample, listFieldsExample } from 'lib/examples';
import { getApplicationUrl } from 'lib/urls';
import { appSection } from 'translations/appSection';

import {
  ApplicationNamePill,
  CodeSectionTitle,
  DocsSection,
  DocsSectionCode,
  DocsSectionText,
} from '../DocSection';
import { ApplicationContext } from './ApplicationContext';

export interface ListFieldsSectionProps {}

export const ListFieldsSection: React.FunctionComponent<ListFieldsSectionProps> = () => {
  const { application, accountId, apiToken } = useContext(ApplicationContext);

  const curlExample = useMemo(
    () => createCurlRequestExample('GET', getApplicationUrl(application.id), accountId, apiToken),
    [application, accountId, apiToken]
  );

  return (
    <DocsSection>
      <DocsSectionText>
        <h3 id={`${application.slug}-fields-list`}>
          <FormattedMessage {...appSection.listFieldsTitle} />
        </h3>
        <p>
          <FormattedMessage {...appSection.listFieldsInfo1} />
        </p>
        <p>
          <FormattedMessage
            {...appSection.listFieldsInfo2}
            values={{
              strong: (text: string) => <strong>{text}</strong>,
              b: (children: string) => <ApplicationNamePill>{children}</ApplicationNamePill>,
              applicationName: application.name,
            }}
          />
        </p>
        <p>
          <code>
            {process.env.REACT_APP_API_DOMAIN}/api/v1/applications/<strong>[Table Id]</strong>/
          </code>
          <ul>
            <li>
              <FormattedMessage {...appSection.listFieldsInfoNote1} />
            </li>
          </ul>
        </p>
      </DocsSectionText>

      <DocsSectionCode>
        <CodeSectionTitle>
          <FormattedMessage {...appSection.exampleRequest} />
        </CodeSectionTitle>

        <SyntaxHighlighter language="bash">{curlExample}</SyntaxHighlighter>

        <CodeSectionTitle>
          <FormattedMessage {...appSection.exampleResponse} />
        </CodeSectionTitle>

        <SyntaxHighlighter language="json">
          {JSON.stringify(listFieldsExample(application), null, 2)}
        </SyntaxHighlighter>
      </DocsSectionCode>
    </DocsSection>
  );
};
