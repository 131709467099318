import { defineMessages } from 'react-intl';

export const appSection = defineMessages({
  applicationName: {
    id: 'applicationName',
    defaultMessage: 'The {applicationName} Table',
  },
  applicationID: {
    id: 'applicationID',
    defaultMessage: 'The ID of this Table is <b>{applicationId}</b>',
  },
  applicationBody1: {
    id: 'applicationBody1',
    defaultMessage:
      'The Table Id must be used when making calls to the API, to ensure that your integration will not be impacted by changes to the Table name.',
  },
  applicationRecords: {
    id: 'applicationRecords',
    defaultMessage:
      'Each record in the <b>{applicationName}</b> Table contains the following fields:',
  },
  createRecordsTitle: {
    id: 'createRecordTitle',
    defaultMessage: 'Create a Record',
  },
  createRecordText1: {
    id: 'createRecordText1',
    defaultMessage:
      'To create a new record, issue a <strong>POST</strong> HTTP request to the <b>{applicationName}</b> endpoint.',
  },
  createRecordNote1: {
    id: 'createRecordNote1',
    defaultMessage: '[Table Id] is the Table’s unique Id',
  },
  createRecordNotes: {
    id: 'createRecordNotes',
    defaultMessage:
      '<ol><li>The request body can be:<ol><li>A single record object</li><li>A JSON object with key “records” with a value containing an array of up to 25 record objects</li></ol></li><li>These objects have a single key field called “fields” whose value is a collection of field name / value pairs.</li></ol>',
  },
  createRecordText2: {
    id: 'createRecordText2',
    defaultMessage:
      'The request body must include the JSON object matching the Table structure, where the keys must be the field ids and the value the associated field value.',
  },
  bulkCreateRecordText: {
    id: 'bulkCreateRecordText',
    defaultMessage: `For information about making a bulk create request, please refer to the SmartSuite Developer's site <link>here</link>.`,
  },
  bulkUpdateRecordText: {
    id: 'bulkUpdateRecordText',
    defaultMessage: `For information about making a bulk update request, please refer to the SmartSuite Developer's site <link>here</link>.`,
  },
  bulkDeleteRecordText: {
    id: 'bulkDeleteRecordText',
    defaultMessage: `For information about making a bulk delete request, please refer to the SmartSuite Developer's site <link>here</link>.`,
  },
  createRecordText3: {
    id: 'createRecordText3',
    defaultMessage:
      'Note that you must include any required fields in the POST or the system will return error code 422 “Invalid Request” - a detailed error message will be included in the response body.',
  },
  createRecordText4: {
    id: 'createRecordText4',
    defaultMessage:
      'The following SmartSuite Fields are system-generated, computed or set by aggregate user actions (ex. voting) and cannot be set via API:',
  },
  deleteRecordTitle: {
    id: 'deleteRecordTitle',
    defaultMessage: 'Delete a Record',
  },
  deleteRecordText1: {
    id: 'deleteRecordText1',
    defaultMessage:
      'To delete <b>{applicationName}</b> records, issue a DELETE request to the record endpoint:',
  },
  deleteRecordTextNotes: {
    id: 'deleteRecordTextNotes',
    defaultMessage:
      '<ul><li>[Table Id] is the Table’s unique Id</li><li>[Record Id] is the record’s unique id</li></ul>',
  },
  deleteRecordText2: {
    id: 'deleteRecordText2',
    defaultMessage:
      'To delete multiple records, pass a JSON object with key “records” and a value containing an array of up to 25 record ids to the Table endpoint.',
  },
  deleteRecordText3: {
    id: 'deleteRecordText3',
    defaultMessage:
      'Deleted records are subject to the same data retention and undelete processes as records deleted by a user via the User Interface.',
  },
  retrieveRecordTitle: {
    id: 'retrieveRecordTitle',
    defaultMessage: 'Retrieve a Record',
  },
  retrieveRecordText1: {
    id: 'retrieveRecordText1',
    defaultMessage:
      'To retrieve a record in <b>{applicationName}</b>, issue a <strong>GET</strong> HTTP request to the table record endpoint.',
  },
  retrieveRecordNotes1: {
    id: 'retrieveRecordNotes1',
    defaultMessage: '[Table Id] is the Table’s unique id',
  },
  retrieveRecordNotes2: {
    id: 'retrieveRecordNotes2',
    defaultMessage: '[Record Id] is the record’s unique id',
  },
  updateRecordTitle: {
    id: 'updateRecordTitle',
    defaultMessage: 'Update a Record',
  },
  updateRecordText1: {
    id: 'updateRecordText1',
    defaultMessage:
      'To update a record in <code>{applicationName}</code>, send a <strong>PATCH / PUT</strong> HTTP request to the table record endpoint:',
  },
  updateRecordText2: {
    id: 'updateRecordText2',
    defaultMessage:
      'The update endpoint supports two types of record updates, with a PUT request performing a “destructive” update that clears all values that are not specified in the update, and a PATCH request updating just those fields included in the request.',
  },
  updateRecordText3: {
    id: 'updateRecordText3',
    defaultMessage:
      '<ul><li>The request body can be:<ul><li>A single record object</li><li>A JSON object with key “records” with a value containing an array of up to 25 record objects</li></ul></li><li>These objects have a single key field called “fields” whose value is a collection of field name / value pairs.</li><li>The request must also contain an “id” property that reflects the unique identifier for the record, which must match an existing record.</li></ul>',
  },
  updateRecordText4: {
    id: 'updateRecordText4',
    defaultMessage:
      'The following SmartSuite Fields are system-generated, computed or set by aggregate user actions (ex. voting) and cannot be set via API:',
  },
  updateRecordText5: {
    id: 'updateRecordText5',
    defaultMessage:
      'To add new records to a Linked Record field, add the record ids to the existing array of ids. Be sure to include all existing linked record IDs that you wish to retain. To unlink records, include the existing array of record IDs, excluding any that you wish to unlink.',
  },
  fieldListingTitle: {
    id: 'fieldListingTitle',
    defaultMessage: 'Fields',
  },
  fieldListingBody1: {
    id: 'fieldListingBody1',
    defaultMessage:
      'Field Ids must be used when making calls to the API, not field names. Using the field id ensures that your integration will remain functional when fields are renamed.',
  },
  listFieldsTitle: {
    id: 'listFieldsTitle',
    defaultMessage: 'List Fields',
  },
  listFieldsInfo1: {
    id: 'listFieldsInfo1',
    defaultMessage:
      'Retrieves a list of all fields, their field type, and their configuration for the selected table.',
  },
  listFieldsInfo2: {
    id: 'listFieldsInfo2',
    defaultMessage:
      'To retrieve a list of fields in <b>{applicationName}</b>, send a <strong>GET</strong> request to the table fields endpoint. Note that you must use Table and Field ids in your request.',
  },
  listFieldsInfoNote1: {
    id: 'listFieldsInfoNote1',
    defaultMessage: '[Table Id] is the Table’s unique id',
  },
  listRecordsTitle: {
    id: 'listRecordsTitle',
    defaultMessage: 'List Records',
  },
  listRecordsInfo1: {
    id: 'listRecordsInfo1',
    defaultMessage: 'Retrieves a listing of records in the Table.',
  },
  listRecordsInfo2: {
    id: 'listRecordsInfo2',
    defaultMessage:
      'To retrieve a list of records in <b>{applicationName}</b>, send a <strong>POST</strong> HTTP request to the Table record endpoint.',
  },
  listRecordsInfo3: {
    id: 'listRecordsInfo3',
    defaultMessage: 'Note: parameters must be URL encoded.',
  },
  listRecordsInfo41: {
    id: 'listRecordsInfo41',
    defaultMessage:
      'For example, to only return data from first_created and primary_field, send these two query parameters:',
  },
  listRecordsInfo42: {
    id: 'listRecordsInfo42',
    defaultMessage:
      'If specified, only field names specified in the list will be included in the results',
  },
  listRecordsInfoNote1: {
    id: 'listFieldsInfoNote5',
    defaultMessage: '[Table Id] is the Table’s unique id',
  },
  listRecordsPageSize: {
    id: 'listRecordsPageSize',
    defaultMessage:
      'Number of records to return per request. If not set, defaults to 100. Note: Value must be less than or equal to 1000. See the Pagination section below for more.',
  },
  listRecordsSortField: {
    id: 'listRecordsInfoSortField',
    defaultMessage:
      'Specifies the fields and directions to be used for ordering the returned records. You may pass one or more fields to sort by. Note: If not set, sorts by creation date.',
  },
  listRecordsFilter: {
    id: 'listRecordsFilter',
    defaultMessage:
      'Filter the records by field value. You can pass one or more objects, each that describes a field, the comparison type, and the value to compare against. See the <a>Fields</a> section for the Available Comparisons for each field.',
  },
  listRecordsOffset: {
    id: 'listRecordsOffset',
    defaultMessage:
      'For use in pagination - specify the offset value returned by the prior record response to retrieve the next page.',
  },
  listRecordsReturns: {
    id: 'listRecordsReturns',
    defaultMessage: 'JSON structure including record id and array of field data, formatted as:',
  },
  listRecordsPagination1: {
    id: 'listRecordsPagination1',
    defaultMessage:
      'The server returns unlimited records by default. You can use the <b>limit</b> parameter to limit the number of records returned per request.',
  },
  listRecordsPagination2: {
    id: 'listRecordsPagination2',
    defaultMessage:
      'When paginated, if there are more records than <b>limit</b>, the response will contain an <b>offset</b>. To fetch the next page of records, include <b>offset</b> in the next request parameters.',
  },
  listRecordsPagination3: {
    id: 'listRecordsPagination3',
    defaultMessage: 'Pagination will stop when you have reached the end of your Table.',
  },
  noContent: {
    id: 'noContent',
    defaultMessage: 'No Content',
  },
  exampleRequest: {
    id: 'example-request',
    defaultMessage: 'Example request',
  },
  exampleResponse: {
    id: 'example-response',
    defaultMessage: 'Example response',
  },
  exampleRequestSingle: {
    id: 'example-request-single',
    defaultMessage: 'Example request - single',
  },
  exampleResponseSingle: {
    id: 'example-response-single',
    defaultMessage: 'Example response - single',
  },
  exampleRequestMultiple: {
    id: 'example-request-multiple',
    defaultMessage: 'Example request - multiple',
  },
  exampleResponseMultiple: {
    id: 'example-response-multiple',
    defaultMessage: 'Example response - multiple',
  },
  exampleText: {
    id: 'exampleText',
    defaultMessage: 'Example Text',
  },
  exampleValue: {
    id: 'exampleValue',
    defaultMessage: 'Example Value',
  },
  loadingMessage: {
    id: 'loadingMessage',
    defaultMessage: 'Loading...',
  },
  availableFilters: {
    id: 'availableFilters',
    defaultMessage: 'Available Filters',
  },
  comparisonLabelTitle: {
    id: 'comparisonLabelTitle',
    defaultMessage: 'Filter',
  },
  comparisonValueTitle: {
    id: 'comparisonValueTitle',
    defaultMessage: 'Parameter',
  },
  queryParametersSectionTitle: {
    id: 'queryParametersSectionTitle',
    defaultMessage: 'Query Parameters',
  },
  bodyParametersSectionTitle: {
    id: 'bodyParametersSectionTitle',
    defaultMessage: 'Body Parameters',
  },
  offsetParameter: {
    id: 'offsetParameter',
    defaultMessage: 'offset',
  },
  limitParameter: {
    id: 'limitParameter',
    defaultMessage: 'limit',
  },
  sortParameter: {
    id: 'sortParameter',
    defaultMessage: 'sort',
  },
  sortType: {
    id: 'sortType',
    defaultMessage: `[ '{ "direction": "asc" | "desc", "field": "field_slug" }' ]`,
  },
  filterParameter: {
    id: 'filterParameter',
    defaultMessage: 'filter',
  },
  filterType: {
    id: 'filterType',
    defaultMessage: `'{ "operator": "or" | "and", "fields": [ { "comparison": "comparison_name", "field": "field_slug", "value": any } ] }'`,
  },
  optional: {
    id: 'optional',
    defaultMessage: 'optional',
  },
  stringType: {
    id: 'stringType',
    defaultMessage: 'string',
  },
  numberType: {
    id: 'numberType',
    defaultMessage: 'number',
  },
  listRecordsHydrating1: {
    id: 'listRecordsHydrating1',
    defaultMessage:
      'To return human-readable values for certain fields, you can include the following JSON in the request body:',
  },
  listRecordsHydrating2: {
    id: 'listRecordsHydrating2',
    defaultMessage: 'Fields that will return additional information with this setting include:',
  },
  listRecordsDeleted1: {
    id: 'listRecordsDeleted1',
    defaultMessage:
      'By default, only active (non-deleted) records are returned by this endpoint. To return deleted records along with active records, include the following parameter with your request:',
  },
  retrieveRecordHydrating1: {
    id: 'retrieveRecordHydrating1',
    defaultMessage:
      'To return human-readable values for certain fields, you can include the following parameter with your query:',
  },
  retrieveRecordHydrating2: {
    id: 'retrieveRecordHydrating2',
    defaultMessage: 'Fields that will return additional information with this setting include:',
  },
});
