import { defineMessages } from 'react-intl';

export const main = defineMessages({
  introduction: {
    id: 'introduction',
    defaultMessage: 'Introduction',
  },
  introductionBody1: {
    id: 'introductionBody1',
    defaultMessage:
      'The <b>{solutionName}</b> API provides an easy way to integrate your <b>{solutionName}</b> data in SmartSuite with any external system. The API closely follows REST semantics, uses JSON to encode objects, and relies on standard HTTP codes to signal operation outcomes.',
  },
  introductionBody2: {
    id: 'introductionBody2',
    defaultMessage:
      'The API documentation below is generated for your Solution <b>{solutionName}</b>. We recommend that you use the SmartSuite user interface to add a few records of data for each table prior to working with the API. The values for the fields in these records will be displayed in the documentation examples generated below.',
  },
  introductionBody3: {
    id: 'introductionBody3',
    defaultMessage: 'The ID of this solution is <b>{solutionId}</b>',
  },
  introductionBody4: {
    id: 'introductionBody4',
    defaultMessage:
      'Please note that if you make changes to a field type, the API interface for those fields will change correspondingly. Therefore, make sure to review your API implementation whenever you make significant changes to your Tables’ structures. Field Ids will remain constant unless you delete and re-created the field.',
  },
  introductionBody5: {
    id: 'introductionBody5',
    defaultMessage:
      'You can also read more about the API in the SmartSuite Help Center: <ul><li><link1>SmartSuite API Overview</link1></li><li><link2>Generating an API Key</link2></li></ul>',
  },
  metaDada: {
    id: 'metaData',
    defaultMessage: 'Metadata',
  },
  metaDataBody1: {
    id: 'metaDataBody1',
    defaultMessage:
      'The SmartSuite API gives you the ability to list all of your Solutions, Tables and their Fields. For more information, please refer to the <link>Metadata API documentation.</link>',
  },
  rateLimits: {
    id: 'rateLimits',
    defaultMessage: 'Rate Limits',
  },
  rateLimitsBodyRate1: {
    id: 'rateLimitsBodyRate1',
    defaultMessage:
      'The API is limited to 5 requests per second per API key. If you exceed this rate, you will receive a 429 status code and will need to wait 30 seconds before subsequent requests will succeed.',
  },
  rateLimitsBodyRate2: {
    id: 'rateLimitsBodyRate2',
    defaultMessage:
      'If you anticipate a higher read volume, we recommend using a caching proxy. This rate limit is the same for all plans and increased limits are not currently available.',
  },

  commonErrors: {
    id: 'commonErrors',
    defaultMessage: 'Common Errors',
  },
  commonErrorsBody1: {
    id: 'commonErrorsBody1',
    defaultMessage:
      'When the API generates an error, the response will return a non-200 status code and a json error message. See the <linkToErrorCodes>Error Codes</linkToErrorCodes> section below for more information about codes SmartSuite generates.',
  },
  commonErrorsBody2: {
    id: 'commonErrorsBody2',
    defaultMessage: 'Common errors include:',
  },
  commonErrorsBody3: {
    id: 'commonErrorsBody3',
    defaultMessage:
      '<b>[Value] is not valid.</b> This error is thrown when a supplied value does not meet the requirements for it’s field type, e.g. an invalid email address (peter@smartsuite)',
  },
  commonErrorsBody4: {
    id: 'commonErrorsBody4',
    defaultMessage:
      '<b>Value out of scale.</b> This error is thrown when a supplied value is not in the range of acceptable values, e.g. setting a value of 10 for a Rating field that has a range of 0-5.',
  },
  commonErrorsBody5: {
    id: 'commonErrorsBody5',
    defaultMessage:
      '<b>[Value] can’t be converted to time.</b> The value supplied cannot be converted to a valid time value.',
  },
  commonErrorsBody6: {
    id: 'commonErrorsBody6',
    defaultMessage:
      '<b>[Value] can’t be converted to list of ObjectIds.</b> The specified value cannot be converted into a list of valid SmartSuite object Ids, such as Member Ids or Linked Record Ids.',
  },

  faq: {
    id: 'faq',
    defaultMessage: 'FAQ',
  },
  faqQuestion1: {
    id: 'faqQuestion1',
    defaultMessage: 'What is Account in reference to?',
  },
  faqAnswer1: {
    id: 'faqAnswer1',
    defaultMessage:
      'Account is the legacy term for what SmartSuite now calls Workspaces. Account Id is synonymous to Workspace Id, which is the first 8 characters of the URL displayed when you are logged in to SmartSuite following app.smartsuite.com/',
  },
  faqQuestion2: {
    id: 'faqQuestion2',
    defaultMessage: 'What is Table in reference to?',
  },
  faqAnswer2: {
    id: 'faqAnswer2',
    defaultMessage:
      'Application is the legacy term for what we now call Tables in SmartSuite. This API uses the word application to refer to Tables.',
  },
  faqQuestion3: {
    id: 'faqQuestion3',
    defaultMessage: 'Does my API Key expire?',
  },
  faqAnswer3: {
    id: 'faqAnswer3',
    defaultMessage:
      'No, API Keys do not expire but they can be regenerated. When you regenerate a key, the old key is immediately invalidated, so be sure to update your integration configuration when you regenerate your key.',
  },
  faqQuestion4: {
    id: 'faqQuestion4',
    defaultMessage: 'What timezone does the API use for timestamps?',
  },
  faqAnswer4: {
    id: 'faqAnswer4',
    defaultMessage:
      'The SmartSuite API always returns timestamps in UTC (Coordinated Universal Time).',
  },
  faqQuestion5: {
    id: 'faqQuestion5',
    defaultMessage: 'Will record changes made by the API generate notifications?',
  },
  faqAnswer5: {
    id: 'faqAnswer5',
    defaultMessage:
      'Yes, notifications are generated by updates that would generate a notification when made using the SmartSuite UI.',
  },
  faqQuestion6: {
    id: 'faqQuestion6',
    defaultMessage: 'What permissions are applied to calls to the SmartSuite API?',
  },
  faqAnswer6: {
    id: 'faqAnswer6',
    defaultMessage:
      'Calls made to the API have the permissions that the user associated with the API Key has at the time of the request. The “last updated by” user will be the user associated with the key as well. We recommend creating an “API User” account to use when performing integration actions that is granted only those permissions needed to perform the tasks associated with the integration actions you are performing.',
  },

  authentication: {
    id: 'authentication',
    defaultMessage: 'Authentication',
  },
  authenticationBody1: {
    id: 'authenticationBody1',
    defaultMessage:
      'SmartSuite uses simple token-based authentication. You can generate or manage your API key in your User Profile. All API requests must be authenticated and made over HTTPS.',
  },
  authenticationBody2: {
    id: 'authenticationBody2',
    defaultMessage:
      'IMPORTANT! Your API key conveys the same privileges as the Member account it is generated in, so it should be treated as securely as a password.',
  },
  authenticationBody3: {
    id: 'authenticationBody3',
    defaultMessage:
      'You authenticate to the API by providing your API key in the <code>Authorization</code> header, as well as your Workspace Id in an <code>Account-Id</code> header, as shown below.',
  },
  authenticationBody4: {
    id: 'authenticationBody4',
    defaultMessage:
      'NOTE: Your Workspace Id is the 8 characters that follow <link>https://app.smartsuite.com/</link> in the SmartSuite URL when you’re logged in.',
  },
  authenticationBody5: {
    id: 'authenticationBody5',
    defaultMessage: 'Example: <example></example>',
  },

  errorCodes: {
    id: 'errorCodes',
    defaultMessage: 'Error Codes',
  },
  errorCodesBody1: {
    id: 'errorCodesBody1',
    defaultMessage:
      'The SmartSuite REST API follows HTTP status code semantics. 2xx codes signify success, 4xx mostly represent user error, 5xx generally correspond to a server error. The error messages will return a JSON-encoded body that contains <code>field ids</code> and a <code>string</code> containing the error message. Those will provide specific error conditions and human-readable messages to identify the source of the error.',
  },
  successCode: {
    id: 'successCode',
    defaultMessage: 'Success code',
  },
  errorCodes200: {
    id: 'errorCodes200',
    defaultMessage: 'Request completed successfully.',
  },
  userErrorCodes: {
    id: 'userErrorCodes',
    defaultMessage: 'User error codes',
  },
  errorCodes400: {
    id: 'errorCodes400',
    defaultMessage: 'The request was invalid or could not be parsed.',
  },
  errorCodes401: {
    id: 'errorCodes401',
    defaultMessage:
      'Provided credentials were invalid or do not have authorization to access the requested resource.',
  },
  errorCodes403: {
    id: 'errorCodes403',
    defaultMessage:
      'Accessing a protected resource with API credentials that don’t have access to that resource.',
  },
  errorCodes404: {
    id: 'errorCodes404',
    defaultMessage:
      'Route or resource is not found. This error is returned when the request hits an undefined route, or if the resource doesn’t exist (e.g. has been deleted).',
  },
  errorCodes422: {
    id: 'errorCodes422',
    defaultMessage: 'The request data is invalid.',
  },
  serverErrorCodes: {
    id: 'serverErrorCodes',
    defaultMessage: 'Server error codes',
  },
  errorCodes500: {
    id: 'errorCodes500',
    defaultMessage: 'The server encountered an unexpected condition.',
  },
  errorCodes502: {
    id: 'errorCodes502',
    defaultMessage:
      'SmartSuite’s servers are restarting or an unexpected outage is in progress. You should rarely encounter this error, and should retry requests if it is generated.',
  },
  errorCodes503: {
    id: 'errorCodes503',
    defaultMessage:
      'The server could not process your request in time. The server could be temporarily unavailable, or it could have timed out processing your request. You should retry the request with backoffs.',
  },

  noResults: {
    id: 'noResults',
    defaultMessage: 'No Results',
  },
  search: {
    id: 'search',
    defaultMessage: 'Search',
  },
});
