import React, { useMemo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { SyntaxHighlighter } from 'components/base/SyntaxHighlighter';
import { createCurlDataExample, listRecordsExample } from 'lib/examples';
import { getApplicationListRecordsUrl } from 'lib/urls';
import { appSection } from 'translations/appSection';

import {
  ApplicationNamePill,
  ClassSpanFieldTypeColor,
  CodeSectionTitle,
  DocsSection,
  DocsSectionCode,
  DocsSectionText,
} from '../DocSection';
import { ApplicationContext } from './ApplicationContext';
import { FieldParameterDoc } from './FieldParameterDoc';

export interface ListRecordsSectionProps {}

export const ListRecordsSection: React.FunctionComponent<ListRecordsSectionProps> = () => {
  const { application, accountId, apiToken } = useContext(ApplicationContext);

  const curlExample = useMemo(
    () =>
      createCurlDataExample(
        'POST',
        getApplicationListRecordsUrl(application.id),
        { sort: [], filter: {} },
        accountId,
        apiToken,
        { offet: 0, limit: 3 }
      ),
    [application, accountId, apiToken]
  );

  return (
    <DocsSection>
      <DocsSectionText>
        <h3 id={`${application.slug}-list`}>
          <FormattedMessage {...appSection.listRecordsTitle} />
        </h3>
        <p>
          <FormattedMessage {...appSection.listRecordsInfo1} />
        </p>
        <p>
          <FormattedMessage
            {...appSection.listRecordsInfo2}
            values={{
              strong: (text: string) => <strong>{text}</strong>,
              b: (children: string) => <ApplicationNamePill>{children}</ApplicationNamePill>,
              applicationName: application.name,
            }}
          />
        </p>

        <p>
          <code>
            {process.env.REACT_APP_API_DOMAIN}/api/v1/applications/<strong>[Table Id]</strong>
            /records/list/
          </code>
          <ul>
            <li>
              <FormattedMessage {...appSection.listRecordsInfoNote1} />
            </li>
          </ul>
        </p>

        <b>
          <FormattedMessage {...appSection.queryParametersSectionTitle} />:
        </b>

        {/* Offset */}
        <FieldParameterDoc
          parameter={appSection.offsetParameter}
          type={appSection.numberType}
          description={<FormattedMessage {...appSection.listRecordsOffset} />}
        />

        {/* Limit */}
        <FieldParameterDoc
          parameter={appSection.limitParameter}
          type={appSection.numberType}
          description={<FormattedMessage {...appSection.listRecordsPageSize} />}
        />

        <RightParagraph>
          <FormattedMessage {...appSection.listRecordsInfo3} />
        </RightParagraph>

        <b>
          <FormattedMessage {...appSection.bodyParametersSectionTitle} />:
        </b>

        {/* Sort */}
        <FieldParameterDoc
          parameter={appSection.sortParameter}
          type={appSection.sortType}
          description={<FormattedMessage {...appSection.listRecordsSortField} />}
          optional
        />

        {/* Filter */}
        <FieldParameterDoc
          parameter={appSection.filterParameter}
          type={appSection.filterType}
          description={
            <FormattedMessage
              {...appSection.listRecordsFilter}
              values={{
                a: (children: string) => (
                  <InternalLink href={`#${application.slug}-fields`}>{children}</InternalLink>
                ),
              }}
            />
          }
          optional
        />

        <h3 id={`${application.slug}-list-pagination`}>
          <FormattedMessage id="listRecordsPagination" defaultMessage="Pagination" />
        </h3>
        <p>
          <FormattedMessage
            {...appSection.listRecordsPagination1}
            values={{
              b: (children: string) => (
                <ClassSpanFieldTypeColor>{children}</ClassSpanFieldTypeColor>
              ),
            }}
          />
        </p>
        <p>
          <FormattedMessage
            {...appSection.listRecordsPagination2}
            values={{
              b: (children: string) => (
                <ClassSpanFieldTypeColor>{children}</ClassSpanFieldTypeColor>
              ),
            }}
          />
        </p>
        <p>
          <FormattedMessage
            {...appSection.listRecordsPagination3}
            values={{
              b: (children: string) => (
                <ClassSpanFieldTypeColor>{children}</ClassSpanFieldTypeColor>
              ),
            }}
          />
        </p>

        <h3 id={`${application.slug}-list-hydrating`}>
          <FormattedMessage id="listRecordsHydrating" defaultMessage="Hydrating Records" />
        </h3>
        <p>
          <FormattedMessage {...appSection.listRecordsHydrating1} />
        </p>
        <p>
          <code>&#123;"hydrated": true&#125;</code>
        </p>
        <p>
          <FormattedMessage {...appSection.listRecordsHydrating2} />

          <ol>
            <li>
              <FormattedMessage id="hydrating-record-1" defaultMessage="Single Select" />
            </li>
            <li>
              <FormattedMessage id="hydrating-record-2" defaultMessage="Multiple Select" />
            </li>
            <li>
              <FormattedMessage id="hydrating-record-3" defaultMessage="Status" />
            </li>
            <li>
              <FormattedMessage id="hydrating-record-4" defaultMessage="First Created" />
            </li>
            <li>
              <FormattedMessage id="hydrating-record-5" defaultMessage="Last Updated" />
            </li>
            <li>
              <FormattedMessage id="hydrating-record-6" defaultMessage="Assigned To" />
            </li>
            <li>
              <FormattedMessage id="hydrating-record-7" defaultMessage="Tags" />
            </li>
            <li>
              <FormattedMessage id="hydrating-record-8" defaultMessage="Vote" />
            </li>
            <li>
              <FormattedMessage id="hydrating-record-9" defaultMessage="Time Tracking Log" />
            </li>
            <li>
              <FormattedMessage id="hydrating-record-10" defaultMessage="Checklist" />
            </li>
            <li>
              <FormattedMessage id="hydrating-record-11" defaultMessage="Lookup" />
            </li>
          </ol>
        </p>

        <h3 id={`${application.slug}-deleted`}>
          <FormattedMessage id="listRecordsDeleted" defaultMessage="Deleted Records" />
        </h3>

        <p>
          <FormattedMessage {...appSection.listRecordsDeleted1} />
        </p>
        <p>
          <code>?all=true</code>
        </p>
      </DocsSectionText>

      <DocsSectionCode>
        <CodeSectionTitle>
          <FormattedMessage {...appSection.exampleRequest} />
        </CodeSectionTitle>

        <SyntaxHighlighter language="bash">{curlExample}</SyntaxHighlighter>

        <CodeSectionTitle>
          <FormattedMessage {...appSection.exampleResponse} />
        </CodeSectionTitle>

        <SyntaxHighlighter language="json">
          {JSON.stringify(listRecordsExample(application), null, 2)}
        </SyntaxHighlighter>
      </DocsSectionCode>
    </DocsSection>
  );
};

const RightParagraph = styled.div`
  text-align: end;
`;

const InternalLink = styled.a`
  color: ${(props) => props.theme.fieldTypeColor};
  text-decoration: none;
`;
