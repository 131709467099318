import { createGlobalStyle } from 'styled-components/macro';

import { Theme } from 'lib/theme';

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.fonts.body}
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    width: 100%;
    margin: 0;
  }
  pre {
    background: hsl(214,5%,20%);
  }
  pre code {
    background: transparent;
    border-radius: 0;
    padding: 0;
  }
  code {
    font-family: ${({ theme }) => theme.fonts.monospace}
    background: #eee;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 13px;
  }
  h2 {
    font-size: 26px;
    color: #2E3538;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;
    color: #333;
    line-height: 23px;
    margin-top: 0;
  }
  /** Trick to make anchors offset the sticky header size */
  h1[id]::before,
  h2[id]::before,
  h3[id]::before,
  h4[id]::before,
  h5[id]::before,
  h6[id]::before {
    content: '';
    display: block;
    visibility: hidden;
    width: 0;
    height: 93px;
    margin-top: 0;
    pointer-events: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.5);
    border-radius: 5px;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.solutionColor}
  }
  a:hover {
    text-decoration: underline;
  }
`;
