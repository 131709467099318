import React, { FunctionComponent, useContext } from 'react';
import styled from 'styled-components/macro';

import { AccountModel } from 'lib/models/account.model';
import { SolutionModel } from 'lib/models/solution.model';

import { Avatar } from './Avatar';
import { CurrentUserContext } from './CurrentUserContext';

export interface UserProfilePanelProps {
  solution?: SolutionModel;
  account?: AccountModel;
}

export const UserProfilePanel: FunctionComponent<UserProfilePanelProps> = ({
  solution,
  account,
}) => {
  const currentUser = useContext(CurrentUserContext);

  const fullName = [currentUser?.first_name, currentUser?.last_name]
    .filter((name) => !!name)
    .join(' ');

  return (
    <UserProfile>
      <div>
        <UserName data-testid="UserName">{fullName}</UserName>
        <AccountName data-testid="UserAccount">{account?.name}</AccountName>
      </div>

      <UserAvatar color={solution?.logo_color} name={fullName} file={currentUser?.profile_image} />
    </UserProfile>
  );
};

const UserProfile = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.h4`
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  color: #232b36;
`;

const AccountName = styled.div`
  font-size: 10px;
  color: #232b36;
  line-height: 15px;
`;

const UserAvatar = styled(Avatar)`
  margin-left: 22px;
`;
