import times from 'lodash/times';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { AppStructureField, FieldChoice } from './models/application.model';
import { RecordModel } from './models/record.model';
import { Fields } from './fields';
import { EmptyFieldsDefaultValue } from './emptyFieldsDefaultValue';

const mapRecordSampleValue = (fieldType: Fields, recordFieldSample: any, index: number): string => {
  if (isNil(recordFieldSample) || isEmpty(recordFieldSample)) {
    if (!EmptyFieldsDefaultValue[fieldType]) {
      return '';
    }

    return JSON.stringify(EmptyFieldsDefaultValue[fieldType][index], null, 2);
  }
  return JSON.stringify(recordFieldSample, null, 2);
};

/** Get all options for a choices field. */
export const getChoicesSampleData = (field: AppStructureField) => {
  // eslint-disable-next-line prefer-destructuring
  const choices: FieldChoice[] = field.params.choices;

  return choices.map(({ value, label }) => `"${value}" (${label})`).join('\n');
};

export const getFieldSampleData = (
  field: AppStructureField,
  records: RecordModel[] = []
): string => {
  if (field.params.choices && field.params.choices.length) {
    return getChoicesSampleData(field);
  }

  return times(3, (i) => {
    const record = records[i];
    const value = record ? record[field.slug] : null;

    return mapRecordSampleValue(field.field_type, value, i);
  }).join('\n');
};
