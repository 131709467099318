import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { main } from 'translations/main';

import { DocsSection, DocsSectionCode, DocsSectionText } from './DocSection';

export const CommonErrorsSection: FunctionComponent = () => (
  <DocsSection>
    <DocsSectionText>
      <h2 id="commonErrors">
        <FormattedMessage {...main.commonErrors} />
      </h2>
      <p>
        <FormattedMessage
          {...main.commonErrorsBody1}
          values={{
            linkToErrorCodes: (children: string) => <a href="#errorCodes">{children}</a>,
          }}
        />
      </p>
      <p>
        <FormattedMessage {...main.commonErrorsBody2} />
      </p>
      <p>
        <FormattedMessage
          {...main.commonErrorsBody3}
          values={{
            b: (children: string) => <b>{children}</b>,
          }}
        />
      </p>
      <p>
        <FormattedMessage
          {...main.commonErrorsBody4}
          values={{
            b: (children: string) => <b>{children}</b>,
          }}
        />
      </p>
      <p>
        <FormattedMessage
          {...main.commonErrorsBody5}
          values={{
            b: (children: string) => <b>{children}</b>,
          }}
        />
      </p>
      <p>
        <FormattedMessage
          {...main.commonErrorsBody6}
          values={{
            b: (children: string) => <b>{children}</b>,
          }}
        />
      </p>
    </DocsSectionText>
    <DocsSectionCode />
  </DocsSection>
);
