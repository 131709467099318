import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { appSection } from 'translations/appSection';

interface FieldParameterDocProps {
  parameter: MessageDescriptor;
  type: MessageDescriptor;
  description: ReactNode;
  optional?: boolean;
}

export const FieldParameterDoc: FunctionComponent<FieldParameterDocProps> = ({
  optional = false,
  parameter,
  type,
  description,
}) => {
  return (
    <Parameters>
      <LeftParametersColumn>
        <RightParagraph>
          <b>
            <FormattedMessage {...parameter} />
          </b>
        </RightParagraph>
        <RightParagraph>{optional && <FormattedMessage {...appSection.optional} />}</RightParagraph>
        <TypeRightParagraph>
          <FormattedMessage {...type} />
        </TypeRightParagraph>
      </LeftParametersColumn>
      <RightParametersColumn>{description}</RightParametersColumn>
    </Parameters>
  );
};

const Parameters = styled.div`
  display: flex;
`;

const LeftParametersColumn = styled.div`
  flex: 35%;
  padding: 10px;
`;

const RightParametersColumn = styled.div`
  flex: 65%;
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
  word-break: break-word;
  color: #333;
  line-height: 22px;
`;

const RightParagraph = styled.div`
  text-align: end;
`;

const TypeRightParagraph = styled(RightParagraph)`
  color: ${(props) => props.theme.fieldTypeColor};
`;
