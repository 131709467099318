import styled from 'styled-components/macro';

import { getWashedColor } from 'lib/colors';

export const DocsSection = styled.section`
  width: 100%;
  display: flex;
`;

export const DocsSectionText = styled.div`
  width: 52%;
  font-size: 13px;
  color: #555555;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;

  h2 {
    margin-bottom: 10px;
    font-weight: 500;
  }

  h2,
  h3 {
    margin-top: -93px;
    pointer-events: none;
  }
`;

export const DocsSectionCode = styled.div`
  padding: 4px 10px 0 10px;
  margin-bottom: -4px;
  width: 48%;
  background: #30303b;
  color: white;
`;

export const SolutionNamePill = styled.span`
  padding: 0 8px;
  display: inline-block;
  line-height: 22px;
  background: ${(props) => getWashedColor(props.theme.solutionColor)};
  color: #2e3538;
  border-radius: 15px;
`;

export const SolutionIdPill = styled.span`
  padding: 0 6px;
  color: #2e3538;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fonts.monospace};
`;

export const ApplicationNamePill = styled(SolutionNamePill)``;

export const ApplicationIdPill = styled(SolutionIdPill)``;

export const ClassSpanFieldTypeColor = styled.code`
  color: ${(props) => props.theme.fieldTypeColor};
`;

export const CodeSectionTitle = styled.div`
  color: #999;
  margin-top: 12px;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 4px;
  background: #30303b;
`;
